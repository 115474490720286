//React
import React from "react";

//Gatsby

import { useStaticQuery, graphql } from "gatsby";
// import Img from 'gatsby-image'
import { GatsbyImage, getImage } from "gatsby-plugin-image";

//Actions

//Components
// import { formatText } from "../../utils/constants";

//Icons

export function useImageData({
  industry,
  size,
  imageShadow,
  imageBorderColor,
  output,
}) {
  const data = useStaticQuery(graphql`
    query {
      footwear: file(
        relativePath: {
          eq: "usecase/industry/kelvin-han-x_7vd201m9I-unsplash.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            height: 150
            width: 120
            transformOptions: { cropFocus: NORTH }
            quality: 100
          )
        }
      }
      footwearLg: file(
        relativePath: {
          eq: "usecase/industry/kelvin-han-x_7vd201m9I-unsplash.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            height: 500
            width: 400
            transformOptions: { cropFocus: NORTH }
            quality: 100
          )
        }
      }
      textiles: file(
        relativePath: {
          eq: "usecase/industry/mel-poole-4byBtNuIyIg-unsplash.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            height: 150
            width: 120
            transformOptions: { cropFocus: NORTH }
            quality: 100
          )
        }
      }
      textilesLg: file(
        relativePath: {
          eq: "usecase/industry/mel-poole-4byBtNuIyIg-unsplash.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            height: 500
            width: 400
            transformOptions: { cropFocus: NORTH }
            quality: 100
          )
        }
      }
      cosmetics: file(
        relativePath: {
          eq: "usecase/industry/nataliya-melnychuk-I-6Ap7JXHq8-unsplash.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            height: 150
            width: 120
            transformOptions: { cropFocus: NORTH }
            quality: 100
          )
        }
      }
      cosmeticsLg: file(
        relativePath: {
          eq: "usecase/industry/nataliya-melnychuk-I-6Ap7JXHq8-unsplash.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            height: 500
            width: 400
            transformOptions: { cropFocus: NORTH }
            quality: 100
          )
        }
      }
      batteries: file(
        relativePath: { eq: "usecase/industry/pexels-js-leng-4374843.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            height: 150
            width: 120
            transformOptions: { cropFocus: SOUTHEAST }
            quality: 100
          )
        }
      }
      batteriesLg: file(
        relativePath: { eq: "usecase/industry/pexels-js-leng-4374843.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            height: 500
            width: 400
            transformOptions: { cropFocus: SOUTHEAST }
            quality: 100
          )
        }
      }
      apparel: file(
        relativePath: { eq: "usecase/industry/pexels-liza-summer-6347888.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            height: 150
            width: 120
            transformOptions: { cropFocus: SOUTHWEST }
            quality: 100
          )
        }
      }
      apparelLg: file(
        relativePath: { eq: "usecase/industry/pexels-liza-summer-6347888.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            height: 500
            width: 400
            transformOptions: { cropFocus: SOUTHWEST }
            quality: 100
          )
        }
      }
      plastics: file(
        relativePath: {
          eq: "usecase/industry/jonathan-chng-OTDyDgPoJ_0-unsplash.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            height: 150
            width: 120
            transformOptions: { cropFocus: CENTER }
            quality: 100
          )
        }
      }
      plasticsLg: file(
        relativePath: {
          eq: "usecase/industry/jonathan-chng-OTDyDgPoJ_0-unsplash.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            height: 500
            width: 400
            transformOptions: { cropFocus: CENTER }
            quality: 100
          )
        }
      }
      chemicals: file(
        relativePath: {
          eq: "usecase/industry/fulvio-ciccolo-Pmkq0yZ80-4-unsplash.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            height: 150
            width: 120
            transformOptions: { cropFocus: CENTER }
            quality: 100
          )
        }
      }
      chemicalsLg: file(
        relativePath: {
          eq: "usecase/industry/fulvio-ciccolo-Pmkq0yZ80-4-unsplash.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            height: 500
            width: 400
            transformOptions: { cropFocus: CENTER }
            quality: 100
          )
        }
      }
      mattresses: file(
        relativePath: {
          eq: "usecase/industry/ty-carlson-I8kTKM17Ktc-unsplash.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            height: 150
            width: 120
            transformOptions: { cropFocus: CENTER }
            quality: 100
          )
        }
      }
      mattressesLg: file(
        relativePath: {
          eq: "usecase/industry/ty-carlson-I8kTKM17Ktc-unsplash.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            height: 500
            width: 400
            transformOptions: { cropFocus: CENTER }
            quality: 100
          )
        }
      }
      furniture: file(
        relativePath: { eq: "usecase/industry/beazy-_9gxlIXdqDQ-unsplash.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            height: 150
            width: 120
            transformOptions: { cropFocus: SOUTHWEST }
            quality: 100
          )
        }
      }
      furnitureLg: file(
        relativePath: { eq: "usecase/industry/beazy-_9gxlIXdqDQ-unsplash.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            height: 500
            width: 400
            transformOptions: { cropFocus: SOUTHWEST }
            quality: 100
          )
        }
      }
      constructionmaterials: file(
        relativePath: {
          eq: "usecase/industry/the-blowup-lqx_D7xIZ2o-unsplash.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            height: 150
            width: 120
            transformOptions: { cropFocus: SOUTHWEST }
            quality: 100
          )
        }
      }
      constructionmaterialsLg: file(
        relativePath: {
          eq: "usecase/industry/the-blowup-lqx_D7xIZ2o-unsplash.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            height: 500
            width: 400
            transformOptions: { cropFocus: SOUTHWEST }
            quality: 100
          )
        }
      }
      electronics: file(
        relativePath: {
          eq: "usecase/industry/jonas-leupe-8pCtwj37VB4-unsplash.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            height: 150
            width: 120
            transformOptions: { cropFocus: SOUTH }
            quality: 100
          )
        }
      }
      electronicsLg: file(
        relativePath: {
          eq: "usecase/industry/jonas-leupe-8pCtwj37VB4-unsplash.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            height: 500
            width: 400
            transformOptions: { cropFocus: SOUTH }
            quality: 100
          )
        }
      }
      nft2FeatureOverview: file(
        relativePath: {
          eq: "featureperspective/feature-overview-nft-mint-2R.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 240
            height: 420
            transformOptions: { cropFocus: NORTH }
          )
        }
      }

      nft2FeatureOverviewAlt: file(
        relativePath: {
          eq: "featureperspective/feature-overview-nft-mint-2.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 240
            height: 420
            transformOptions: { cropFocus: NORTH }
          )
        }
      }
      nft4FeatureOverview: file(
        relativePath: {
          eq: "featureperspective/resortsplus-usecases/resortsplus-norway-community-R.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 240
            height: 420
            transformOptions: { cropFocus: NORTH }
          )
        }
      }
      nft5FeatureOverview: file(
        relativePath: {
          eq: "featureperspective/passport-usecases/passport-planner-attribute-editor-R.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 240
            height: 420
            transformOptions: { cropFocus: NORTH }
          )
        }
      }
      nft5FeatureOverviewAlt: file(
        relativePath: {
          eq: "featureperspective/passport-usecases/passport-planner-attribute-editor-L.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 240
            height: 420
            transformOptions: { cropFocus: NORTH }
          )
        }
      }
      nft6FeatureOverview: file(
        relativePath: {
          eq: "featureperspective/contentnetwork-usecases/contentnetwork-retail-athleisure-L.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 240
            height: 420
            transformOptions: { cropFocus: NORTH }
          )
        }
      }
      nft6FeatureOverviewAlt: file(
        relativePath: {
          eq: "featureperspective/contentnetwork-usecases/contentnetwork-retail-athleisure-R.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 240
            height: 420
            transformOptions: { cropFocus: NORTH }
          )
        }
      }
      nft7FeatureOverview: file(
        relativePath: {
          eq: "featureperspective/passport-usecases/passport-token-event-shoes-L.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 240
            height: 420
            transformOptions: { cropFocus: NORTH }
          )
        }
      }
      nft7FeatureOverviewAlt: file(
        relativePath: {
          eq: "featureperspective/passport-usecases/passport-token-event-shoes-R.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 240
            height: 420
            transformOptions: { cropFocus: NORTH }
          )
        }
      }
      nft8FeatureOverview: file(
        relativePath: {
          eq: "featureperspective/passport-usecases/dpp-planner-genai-L.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 240
            height: 420
            transformOptions: { cropFocus: NORTH }
          )
        }
      }
      nft8FeatureOverviewAlt: file(
        relativePath: {
          eq: "featureperspective/passport-usecases/dpp-planner-genai-R.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 240
            height: 420
            transformOptions: { cropFocus: NORTH }
          )
        }
      }
      nft1LoyaltyB: file(
        relativePath: {
          eq: "featureperspective/resortsplus-usecases/resortsplus-cairo-tour-R.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 240
            height: 420
            transformOptions: { cropFocus: NORTH }
          )
        }
      }
      nft1LoyaltyC: file(
        relativePath: {
          eq: "featureperspective/resortsplus-usecases/resortsplus-lisbon-breakfast-L.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 240
            height: 420
            transformOptions: { cropFocus: NORTH }
          )
        }
      }
      nft1LoyaltyC1: file(
        relativePath: {
          eq: "featureperspective/resortsplus-usecases/resortsplus-lisbon-breakfast-R.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 240
            height: 420
            transformOptions: { cropFocus: NORTH }
          )
        }
      }
      nft1Social: file(
        relativePath: {
          eq: "featureperspective/resortsplus-usecases/resortsplus-impact-africawater-L.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 240
            height: 420
            transformOptions: { cropFocus: NORTH }
          )
        }
      }
      nft1ASocial: file(
        relativePath: {
          eq: "featureperspective/resortsplus-usecases/resortsplus-carbon-capture-generic-L.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 240
            height: 420
            transformOptions: { cropFocus: NORTH }
          )
        }
      }
      nft1ATransparency: file(
        relativePath: {
          eq: "feature/resortsplus-sustainability-transparency-raw.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 380
            height: 420
            transformOptions: { cropFocus: NORTHWEST }
          )
        }
      }
      nft1BTransparency: file(
        relativePath: {
          eq: "feature/resortsplus-sustainability-transparency-lisbon.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 380
            height: 420
            transformOptions: { cropFocus: NORTHWEST }
          )
        }
      }
      nft1ASocialAlt: file(
        relativePath: {
          eq: "featureperspective/resortsplus-usecases/resortsplus-carbon-capture-generic-R.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 240
            height: 420
            transformOptions: { cropFocus: NORTH }
          )
        }
      }
      nft2EventBands: file(
        relativePath: { eq: "featureperspective/feature-nft-3-mint-band.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 240
            height: 420
            transformOptions: { cropFocus: NORTH }
          )
        }
      }
      nft2EventBandsAlt: file(
        relativePath: { eq: "featureperspective/feature-nft-3-mint-band-R.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 240
            height: 420
            transformOptions: { cropFocus: NORTH }
          )
        }
      }
      nft2EventBandsStore: file(
        relativePath: { eq: "featureperspective/feature-nft-4-store-band.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 240
            height: 420
            transformOptions: { cropFocus: NORTH }
          )
        }
      }
      nft2LoyaltyImpact: file(
        relativePath: {
          eq: "featureperspective/trailblazer-usecases/galapagos-community-L.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 240
            height: 420
            transformOptions: { cropFocus: NORTH }
          )
        }
      }
      nft2LoyaltyImpactA: file(
        relativePath: {
          eq: "featureperspective/resortsplus-usecases/resortsplus-impact-beachcleanup-R.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 240
            height: 420
            transformOptions: { cropFocus: NORTH }
          )
        }
      }
      nft2LoyaltyImpactB: file(
        relativePath: {
          eq: "featureperspective/resortsplus-usecases/resortsplus-impact-africawater-L.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 240
            height: 420
            transformOptions: { cropFocus: NORTH }
          )
        }
      }
      nft3LoyaltyA: file(
        relativePath: { eq: "featureperspective/feature-loyalty-engage-R.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 240
            height: 420
            transformOptions: { cropFocus: NORTH }
          )
        }
      }
      nft3LoyaltyC: file(
        relativePath: {
          eq: "featureperspective/trailblazer-usecases/trailblazer-adventures-community-sms-L.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 240
            height: 420
            transformOptions: { cropFocus: NORTH }
          )
        }
      }
      nftLoyaltyRedeem: file(
        relativePath: {
          eq: "featureperspective/feature-loyalty-redeem-qrcode-R.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 240
            height: 420
            transformOptions: { cropFocus: NORTH }
          )
        }
      }
      nftLoyaltyRedeem2: file(
        relativePath: {
          eq: "featureperspective/feature-loyalty-redeem-qrcode-L.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 240
            height: 420
            transformOptions: { cropFocus: NORTH }
          )
        }
      }
      nftLoyaltyRedeemEvent: file(
        relativePath: {
          eq: "featureperspective/feature-loyalty-redeem-qrcode-event-R.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 240
            height: 420
            transformOptions: { cropFocus: NORTH }
          )
        }
      }
      nft3Social: file(
        relativePath: {
          eq: "featureperspective/resortsplus-usecases/resortsplus-impact-thaijungle-L.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 240
            height: 420
            transformOptions: { cropFocus: NORTH }
          )
        }
      }
      nft3SocialAlt: file(
        relativePath: {
          eq: "featureperspective/resortsplus-usecases/resortsplus-impact-thaijungle-R.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 240
            height: 420
            transformOptions: { cropFocus: NORTH }
          )
        }
      }
      nft3Climate: file(
        relativePath: {
          eq: "featureperspective/resortsplus-usecases/wallet-explorer-token-event-carbonremoval-mobile-short-R.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 240
            height: 420
            transformOptions: { cropFocus: NORTH }
          )
        }
      }
      nft3ClimateSend: file(
        relativePath: {
          eq: "featureperspective/resortsplus-usecases/resortsplus-tokenevent-send-L.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 240
            height: 420
            transformOptions: { cropFocus: NORTH }
          )
        }
      }
      nft3ClimateSendAlt: file(
        relativePath: {
          eq: "featureperspective/resortsplus-usecases/resortsplus-tokenevent-send-R.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 240
            height: 420
            transformOptions: { cropFocus: NORTH }
          )
        }
      }
      nft3ClimateEco: file(
        relativePath: {
          eq: "featureperspective/resortsplus-usecases/resortsplus-impact-beachcleanup-R.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 240
            height: 420
            transformOptions: { cropFocus: NORTH }
          )
        }
      }
      nft4ClimateEco: file(
        relativePath: {
          eq: "featureperspective/resortsplus-usecases/resortsplus-lisbon-sustainability-L.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 240
            height: 420
            transformOptions: { cropFocus: NORTH }
          )
        }
      }
      featureBlank: file(relativePath: { eq: "feature/iphone-frame.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 240
            height: 260
            transformOptions: { cropFocus: NORTH }
          )
        }
      }
      auth1Content: file(
        relativePath: {
          eq: "featureperspective/contentnetwork-usecases/contentnetwork-retail-athleisure-L.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 240
            height: 420
            transformOptions: { cropFocus: NORTH }
          )
        }
      }
      auth1ContentAlt: file(
        relativePath: {
          eq: "featureperspective/contentnetwork-usecases/contentnetwork-retail-athleisure-R.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 240
            height: 420
            transformOptions: { cropFocus: NORTH }
          )
        }
      }
      auth2Content: file(
        relativePath: {
          eq: "featureperspective/contentnetwork-usecases/contentnetwork-retail-fashion-token-event-R.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 240
            height: 420
            transformOptions: { cropFocus: NORTH }
          )
        }
      }
      auth3Content: file(
        relativePath: {
          eq: "featureperspective/contentnetwork-usecases/contentnetwork-retail-fashion-wallet-explorer-R.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 240
            height: 420
            transformOptions: { cropFocus: NORTH }
          )
        }
      }
      auth4Content: file(
        relativePath: {
          eq: "featureperspective/contentnetwork-usecases/contentnetwork-retail-fashion-minting-R.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 240
            height: 420
            transformOptions: { cropFocus: NORTH }
          )
        }
      }
      localAccessPass1: file(
        relativePath: {
          eq: "featureperspective/resortsplus-usecases/accesspass-wallet-rewardlist-short-L.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 240
            height: 420
            transformOptions: { cropFocus: NORTH }
          )
        }
      }
      localAccessPass1B: file(
        relativePath: {
          eq: "featureperspective/resortsplus-usecases/francepass-wallet-rewardlist-short-R.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 240
            height: 420
            transformOptions: { cropFocus: NORTH }
          )
        }
      }
      localAccessPass2: file(
        relativePath: {
          eq: "featureperspective/resortsplus-usecases/francepass-wallet-chemoi-restaurant-R.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 240
            height: 420
            transformOptions: { cropFocus: NORTH }
          )
        }
      }
      localAccessPass3: file(
        relativePath: {
          eq: "featureperspective/resortsplus-usecases/resortsplus-rome-cookingclass-L.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 240
            height: 420
            transformOptions: { cropFocus: NORTH }
          )
        }
      }
      localAccessPass4: file(
        relativePath: {
          eq: "featureperspective/resortsplus-usecases/resortsplus-kitesurfing-qrcode-R.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 240
            height: 420
            transformOptions: { cropFocus: NORTH }
          )
        }
      }
      productPassportQR: file(
        relativePath: {
          eq: "stock/pexels/pexels-kampus-production-7289717.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 240
            height: 420
            transformOptions: { cropFocus: NORTH }
          )
        }
      }
      productPassportMinting: file(
        relativePath: {
          eq: "featureperspective/passport-usecases/passport-minting-shoes-R.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 240
            height: 420
            transformOptions: { cropFocus: NORTH }
          )
        }
      }
      productPassportMintingAlt: file(
        relativePath: {
          eq: "featureperspective/passport-usecases/fashionnetwork-passport-shoe-R.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 240
            height: 420
            transformOptions: { cropFocus: NORTH }
          )
        }
      }
      productPassportTokenEvent: file(
        relativePath: {
          eq: "featureperspective/passport-usecases/passport-token-event-shoes-L.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 240
            height: 420
            transformOptions: { cropFocus: NORTH }
          )
        }
      }
      productPassportWalletExplorer: file(
        relativePath: {
          eq: "featureperspective/passport-usecases/passport-wallet-explorer-R.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 240
            height: 420
            transformOptions: { cropFocus: NORTH }
          )
        }
      }
      productPassportWalletExplorerAlt: file(
        relativePath: {
          eq: "featureperspective/passport-usecases/passport-wallet-explorer-L.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 240
            height: 420
            transformOptions: { cropFocus: NORTH }
          )
        }
      }
      productPassportLifestyle: file(
        relativePath: {
          eq: "featureperspective/passport-usecases/fashionnetwork-autumncollection-L.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 240
            height: 420
            transformOptions: { cropFocus: NORTH }
          )
        }
      }
      productPassportLifestyleAlt: file(
        relativePath: {
          eq: "featureperspective/passport-usecases/fashionnetwork-autumncollection-R.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 240
            height: 420
            transformOptions: { cropFocus: NORTH }
          )
        }
      }
      calloutStock: file(
        relativePath: { eq: "featurecallout/design-library-stock-warp.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutTemplates: file(
        relativePath: { eq: "featurecallout/design-templates-warp.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutTemplateAccess: file(
        relativePath: { eq: "featurecallout/design-template-access-warp.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutStickers: file(
        relativePath: { eq: "featurecallout/design-stickers-warp.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutLibraryVideo: file(
        relativePath: { eq: "featurecallout/design-library-videos-warp.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutTokenDesign: file(
        relativePath: {
          eq: "featurecallout/design-callout-resortsplus-cairo2.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutTransparency1: file(
        relativePath: { eq: "featurecallout/transparency-content-auth.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutTransparency2: file(
        relativePath: { eq: "featurecallout/transparency-event-ui.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutTransparency3: file(
        relativePath: { eq: "featurecallout/transparency-report.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutTransparency4: file(
        relativePath: { eq: "featurecallout/dppplanner-sendtodpp.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutTransparency5: file(
        relativePath: { eq: "featurecallout/transparency-event-date.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutTransparency6: file(
        relativePath: { eq: "featurecallout/transparency-offchain.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutTransparency7: file(
        relativePath: { eq: "featurecallout/dppplanner-elements.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutMinting1: file(
        relativePath: { eq: "featurecallout/minting-custom-address.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutMinting2: file(
        relativePath: { eq: "featurecallout/minting-erc1155.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutMinting3: file(
        relativePath: { eq: "featurecallout/minting-networks-sepolia.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutMinting4: file(
        relativePath: { eq: "featurecallout/minting-transfer.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutMinting5: file(
        relativePath: { eq: "featurecallout/minting-wallet-support.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutMinting6: file(
        relativePath: { eq: "featurecallout/minting-networks-logo-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutMinting7: file(
        relativePath: {
          eq: "featurecallout/minting-smart-contract-selector-sepolia.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutMinting8: file(
        relativePath: { eq: "featurecallout/minting-cloud.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutMinting9: file(
        relativePath: { eq: "featurecallout/qrcode-pico.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutLoyalty1: file(
        relativePath: { eq: "featurecallout/loyalty-couponcode.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutLoyalty2: file(
        relativePath: { eq: "featurecallout/loyalty-custombranding.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutLoyalty3: file(
        relativePath: { eq: "featurecallout/loyalty-expiration.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutLoyalty4: file(
        relativePath: { eq: "featurecallout/loyalty-manualredemption.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutLoyalty5: file(
        relativePath: { eq: "featurecallout/loyalty-offchain.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutLoyalty6: file(
        relativePath: { eq: "featurecallout/loyalty-qrcode.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutLoyalty7: file(
        relativePath: { eq: "featurecallout/loyalty-reward.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutLoyalty8: file(
        relativePath: { eq: "featurecallout/loyalty-rewardexample.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutLoyalty9: file(
        relativePath: { eq: "featurecallout/loyalty-socialimpact.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutCommunity1: file(
        relativePath: { eq: "featurecallout/community-curatedposts.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutCommunity2: file(
        relativePath: { eq: "featurecallout/community-externallink.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutCommunity3: file(
        relativePath: { eq: "featurecallout/community-forestretreat.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutCommunity4: file(
        relativePath: { eq: "featurecallout/community-posts.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutCommunity5: file(
        relativePath: { eq: "featurecallout/community-sms.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutCommunity6: file(
        relativePath: { eq: "featurecallout/community-surfcamp.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutCommunity7: file(
        relativePath: { eq: "featurecallout/community-tokenaccess.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutWalletExplorer1: file(
        relativePath: { eq: "featurecallout/walletexplorer-francepass.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutWalletExplorer2: file(
        relativePath: { eq: "featurecallout/walletexplorer-redeem.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutWalletExplorer3: file(
        relativePath: { eq: "featurecallout/walletexplorer-rewards.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutWalletExplorer4: file(
        relativePath: { eq: "featurecallout/walletexplorer-dpp-tokens.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutWalletExplorer5: file(
        relativePath: {
          eq: "featurecallout/walletexplorer-dpp-productcomposition.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutWalletExplorer6: file(
        relativePath: { eq: "featurecallout/walletexplorer-collection.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutWalletExplorer7: file(
        relativePath: { eq: "featurecallout/walletexplorer-dpp-filter.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutEventAccess1: file(
        relativePath: { eq: "featurecallout/eventaccess-roles.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutItemBatch1: file(
        relativePath: { eq: "featurecallout/itembatch-search.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutMarketplace1: file(
        relativePath: { eq: "featurecallout/marketplace-cookingclass.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutMarketplace2: file(
        relativePath: { eq: "featurecallout/marketplace-custombrand.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutMarketplace3: file(
        relativePath: { eq: "featurecallout/marketplace-details.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutMarketplace4: file(
        relativePath: { eq: "featurecallout/marketplace-merchandise.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutMarketplace5: file(
        relativePath: { eq: "featurecallout/marketplace-token.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutOnboarding1: file(
        relativePath: { eq: "featurecallout/onboarding-custombrand.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutOnboarding2: file(
        relativePath: { eq: "featurecallout/onboarding-prompt.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutOnboarding3: file(
        relativePath: { eq: "featurecallout/onboarding-customize.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutOnboarding4: file(
        relativePath: { eq: "featurecallout/onboarding-download-wallet.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutOnboarding5: file(
        relativePath: { eq: "featurecallout/onboarding-walletexplorer.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutPlanner1: file(
        relativePath: { eq: "featurecallout/dppplanner-attributes.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutPlanner2: file(
        relativePath: { eq: "featurecallout/dppplanner-products.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutPlanner3: file(
        relativePath: { eq: "featurecallout/dppplanner-template-custom.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutPlanner4: file(
        relativePath: { eq: "featurecallout/dppplanner-richtext-editor.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutPlanner5: file(
        relativePath: { eq: "featurecallout/dppplanner-icons-2.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutPlanner6: file(
        relativePath: { eq: "featurecallout/dppplanner-integrated-minting.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutGenAi1: file(
        relativePath: { eq: "featurecallout/genai-rawdata.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutGenAi2: file(
        relativePath: { eq: "featurecallout/genai-generate.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutGenAi3: file(
        relativePath: { eq: "featurecallout/genai-refine.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutGenAi4: file(
        relativePath: { eq: "featurecallout/genai-options.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutGenAi5: file(
        relativePath: { eq: "featurecallout/genai-models.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutGenAi6: file(
        relativePath: { eq: "featurecallout/genai-reviewedit.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutGenAi7: file(
        relativePath: { eq: "featurecallout/genai-security.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutPermissions1: file(
        relativePath: {
          eq: "featurecallout/permissionsdata-usermanagement.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutPermissions2: file(
        relativePath: { eq: "featurecallout/permissionsdata-smartcontract.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutPermissions3: file(
        relativePath: { eq: "featurecallout/permissionsdata-datacenter-eu.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutPermissions4: file(
        relativePath: { eq: "featurecallout/permissionsdata-apis.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutPermissions5: file(
        relativePath: { eq: "featurecallout/customer-simplechic-detail.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutTokenCollection1: file(
        relativePath: { eq: "featurecallout/tokencollection-collection.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutTokenCollection2: file(
        relativePath: { eq: "featurecallout/tokencollection-custominfo.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutTokenCollection3: file(
        relativePath: { eq: "featurecallout/tokencollection-offchain.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutTokenCollection4: file(
        relativePath: { eq: "featurecallout/tokencollection-qrcode.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutTokenCollection5: file(
        relativePath: { eq: "featurecallout/tokencollection-selecttokens.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      calloutTokenCollection6: file(
        relativePath: { eq: "featurecallout/tokencollection-tileimage.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 249
            height: 200
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      playpark: file(
        relativePath: { eq: "customers/playpark/playpark-logo.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 240
            height: 100
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      imagos: file(relativePath: { eq: "customers/playpark/imagos-logo.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 75
            height: 75
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      tomorrowsair: file(
        relativePath: { eq: "customers/tomorrowsair/tomorrowsair-logo.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 275
            height: 75
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      bonandberg: file(
        relativePath: { eq: "customers/bonandberg/bonandberg-logo.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 305
            height: 75
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      simplechic: file(
        relativePath: { eq: "customers/simplechic/simplechic-logo.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 305
            height: 150
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      themorphbag: file(
        relativePath: { eq: "customers/themorphbag/gsk-morphbag-logo.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 305
            height: 75
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      tammam: file(relativePath: { eq: "customers/tammam/tammam-logo.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 235
            height: 75
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      fibreforgood: file(
        relativePath: { eq: "customers/fibreforgood/ffg-logo.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 230
            height: 130
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      weartek: file(
        relativePath: { eq: "customers/weartek/weartek-logo.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 130
            height: 80
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
    }
  `);
  console.log("data");
  console.log(data);
  console.log("industry");
  console.log(industry);
  if (output === "src") {
    return data?.[`${industry}${size}`]?.childImageSharp?.gatsbyImageData
      ?.images?.fallback?.src;
  } else {
    return data;
  }
  // const image = getImage(data?.[`${industry}${size}`])
  // if (output === 'src') {
  //   return data?.[`${industry}${size}`]?.childImageSharp?.gatsbyImageData
  //     ?.images?.fallback?.src
  // } else {
  //   return (
  //     <GatsbyImage
  //       className={`rounded-corners ${imageShadow ? 'shadow' : ''} ${
  //         imageBorderColor ? `border-1-${imageBorderColor}` : ''
  //       } ${size === 'Lg' ? 'industry-header-image' : ''}`}
  //       image={image}
  //     />

  //     // <Img
  //     //   className={`rounded-corners ${imageShadow ? 'shadow' : ''} ${
  //     //     imageBorderColor ? `border-1-${imageBorderColor}` : ''
  //     //   }`}
  //     //   fixed={data?.[`${industry}${size}`]?.childImageSharp?.fixed}
  //     // />
  //   )
  // }
}

export default function Snippet({
  industry,
  size,
  imageShadow,
  imageBorderColor,
  output,
  className,
}) {
  const data = useImageData(industry, size, output);
  const image = getImage(data?.[`${industry}${size}`]);
  return (
    <GatsbyImage
      className={`rounded-corners ${imageShadow ? "shadow" : ""} ${
        imageBorderColor ? `border-1-${imageBorderColor}` : ""
      } ${size === "Lg" ? "industry-header-image" : ""} ${
        className ? className : ""
      }`}
      image={image}
    />
  );
}
// export function getImageData({
//   industry,
//   size,
//   imageShadow,
//   imageBorderColor,
//   output,
// }) {
//   const data = useImageData(industry, size)
//   const image = getImage(data?.[`${industry}${size}`])
//   return (
//     <GatsbyImage
//       className={`rounded-corners ${imageShadow ? 'shadow' : ''} ${
//         imageBorderColor ? `border-1-${imageBorderColor}` : ''
//       } ${size === 'Lg' ? 'industry-header-image' : ''}`}
//       image={image}
//     />
//   )
// }
