//React
import React from "react";

//Gatsby
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

//Packages

//Components
import Layout from "../../components/layout";
import { Metatags } from "../../components/marketing/metatags";

import CalloutSupportSection from "../../components/marketing/calloutSupportSection";
import FeatureLink from "../../components/marketing/featureLink2";
import YoutubeComponent from "../../components/marketing/youtubeComponent";

//Icons
// import { FaArrowRight } from "react-icons/fa";

const capabilityData = {
  // titleelement: 'h2',
  // title: 'PicoNext capabilities',
  // titlecolor: 'black-4',
  // subtitle:
  //   'PicoNext helps you attract new Gen Z + Millennial customers, and build branded communities with an end-to-end, no-code NFT platform',
  mode: "quad",
  direction: "column",
  stretch: true,
  // bggradient: 'gradient-callout-algae',
  rowdata: [
    {
      // rowbgcolor: "green",
      rowdata: [
        {
          title: "Summarize documents and Web sites",
          description:
            "Add Word documents, PowerPoint presentations, and Web URLs as input data for AI to summarize into a DPP",
          // colbgcolor: 'sky-1',
          colbggradient: "gradient-callout-silver",
          // colbordercolor: 'sky-1',
          titlecolor: "black-4",
          desccolor: "black-3",
          iconcolor: "sky",

          iconname: "collection",
          image: "calloutGenAi1",
        },
        {
          title: "Use generative AI to distill sustainability info",
          description:
            "Analyze input data with AI to produce summarized sustainability data that aligns with DPP industry templates",
          // colbgcolor: 'sky-1',
          colbggradient: "gradient-callout-silver",
          // colbordercolor: 'sky-1',
          titlecolor: "black-4",
          desccolor: "black-3",
          iconcolor: "sky",

          iconname: "collection",
          image: "calloutGenAi2",
        },
        {
          title: "Fine-tune output",
          description:
            "Use custom, follow-on prompts to refine output produced by generative AI, on a per-item basis",
          // colbgcolor: 'sky-1',
          colbggradient: "gradient-callout-silver",
          // colbordercolor: 'sky-1',
          titlecolor: "black-4",
          desccolor: "black-3",
          iconcolor: "sky",

          iconname: "collection",
          image: "calloutGenAi3",
        },
        {
          title: "Customize generative AI settings",
          description:
            "Customize the settings for generative AI output, including target word count, temperature, and frequency penalty",
          // colbgcolor: 'sky-1',
          colbggradient: "gradient-callout-silver",
          // colbordercolor: 'sky-1',
          titlecolor: "black-4",
          desccolor: "black-3",
          iconcolor: "sky",

          iconname: "collection",
          image: "calloutGenAi4",
        },
        {
          title: "Switch foundational models",
          description:
            "Select between multiple foundational large language models for text generation, including OpenAI GPT 3.5 Turbo, OpenAI GPT 4o, and Google Gemini Flash 1.5",
          // colbgcolor: 'sky-1',
          colbggradient: "gradient-callout-silver",
          // colbordercolor: 'sky-1',
          titlecolor: "black-4",
          desccolor: "black-3",
          iconcolor: "sky",

          iconname: "collection",
          image: "calloutGenAi5",
        },
        {
          title: "Review and edit",
          description:
            "Manually review AI-generated content for accuracy, and edit information before publishing.",
          // colbgcolor: 'sky-1',
          colbggradient: "gradient-callout-silver",
          // colbordercolor: 'sky-1',
          titlecolor: "black-4",
          desccolor: "black-3",
          iconcolor: "sky",

          iconname: "collection",
          image: "calloutGenAi6",
        },
        {
          title: "Data privacy",
          description:
            "Utilize the AI Assistant knowing that no customer content is used to train the underlying AI model",
          // colbgcolor: 'sky-1',
          colbggradient: "gradient-callout-silver",
          // colbordercolor: 'sky-1',
          titlecolor: "black-4",
          desccolor: "black-3",
          iconcolor: "sky",

          iconname: "collection",
          image: "calloutGenAi7",
        },
      ],
    },
  ],
};

const About = (props) => (
  <Layout>
    <div class="gradient-callout-cousteau py-2 mb-2">
      <div class="container my-2 py-4">
        <div class="row">
          <div class="col-lg-10 offset-lg-1">
            <div class="d-flex flex-column align-items-center justify-content-center">
              <h3 class="fw-light text-center white-1">
                Use AI to summarize complex sustainability data into Digital
                Product Passports
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="">
      <div class="container container-page my-0 pt-4 pb-2">
        <div class="row">
          <div class="col-md-4 d-flex flex-column align-items-center justify-content-center text-center text-lg-center">
            <div
              class="d-flex align-items-start justify-content-center"
              // style={{ minHeight: 580 }}
            >
              <StaticImage
                src="../../images/featureperspective/passport-usecases/dpp-planner-genai-R.png"
                alt="PicoNext"
                // loading="lazy"
                placeholder="blurred"
                layout="constrained"
                width={275}
                height={400}
                transformOptions={{ cropFocus: "north" }}
                // height={568}
              />
              {/* <StaticImage
                    style={{
                      position: 'absolute',
                      zIndex: 3,
                    }}
                    src="../../images/feature/iphone-frame.png"
                    alt="PicoNext"
                    // loading="lazy"
                    placeholder="blurred"
                    layout="constrained"

                    width={275}
                    // height={568}
                  />
                  <div
                    class="rounded-corners"
                    style={{ height: 545, overflow: 'hidden' }}
                  >
                    <video
                      autoPlay
                      muted
                      loop
                      playsInline
                      width="250"
                      style={{
                        zIndex: 2,
                        marginTop: 30,
                        // marginLeft: 12,
                      }}
                    >
                      <source
                        src="https://picovideomedia.s3.amazonaws.com/static/video/1-design.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </div> */}
            </div>
          </div>
          <div class="col-md-8 d-flex flex-column align-items-center justify-content-center text-center text-lg-center">
            <h1 class="fw-normal black-1 display-2 page-title">
              Create DPPs with Generative AI
            </h1>

            <p class="display-4 line-height-4 p-2">
              Use generative AI to accelerate your workflow by summarizing raw
              sustainability data into Digital Product Passports
            </p>
            <Link to="/demo/video/" class="btn btn-outline-primary">
              See demo &rarr;
              {/* <FaArrowRight style={{ marginTop: -3 }} /> */}
            </Link>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-3"></div>

    <hr size={1} />
    <div class="gradient-callout-silver-reverse">
      <div class="container my-2 py-4">
        <div class="row">
          <div class="col-12">
            <h3 class="text-center mb-4 black-4">DPP Generative AI features</h3>
            <div class="d-flex flex-row flex-wrap justify-content-center">
              {capabilityData.rowdata[0].rowdata.map((item) => (
                <FeatureLink
                  title={item.title}
                  desc={item.description}
                  image={item.image ? item.image : ""}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="">
      <div class="container my-2 mt-4 py-4">
        <div class="row">
          <div class="col-12">
            <h3 class="text-center mb-4 black-4">
              Take a video tour of Generative AI for Digital Product Passports
            </h3>
            <div class="d-flex flex-row flex-wrap justify-content-center">
              <YoutubeComponent id="JNy4WgxNwYg" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr size={1} />

    <CalloutSupportSection
      title="DPP Generative AI"
      article="dpp-planner-ai"
      color="bluegreen"
    />
  </Layout>
);

export default About;

export const Head = ({ data }) => {
  return (
    <Metatags
      title="Use Generative AI to Create Digital Product Passports"
      description="Use AI to summarize complex sustainability data into Digital Product Passports"
      image={`https://${process.env.HOSTNAME}${data?.metadataImage?.edges?.[0]?.node?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}`}
    />
  );
};

export const pageQuery = graphql`
  query {
    metadataImage: allFile(
      filter: {
        relativePath: {
          eq: "featureperspective/passport-usecases/dpp-planner-genai-L.png"
        }
      }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
