//React
import React from "react";

//Icons
// import { FaPhotoVideo } from 'react-icons/fa'
// import { MdPeople, MdSearch, MdOutlineQueueMusic } from 'react-icons/md'
// import { BiBullseye, BiExport } from 'react-icons/bi'
// import { RiSmartphoneLine, RiContrastFill } from 'react-icons/ri'
// import { AiOutlineEdit } from 'react-icons/ai'
// import { CgSmileMouthOpen } from 'react-icons/cg'
// import { IoImagesOutline } from 'react-icons/io5'

import Check from "../../images/svg/pricing/check.svg";

const Snippet = (props) => (
  <>
    <div class="table-responsive pricing-plan-comparison-table-container">
      <table class="table pricing-plan-comparison-table">
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col">
              <div>Starter</div>
            </th>
            <th scope="col">
              <div>Essentials</div>
            </th>
            <th scope="col">
              <div>Pro</div>
            </th>
            <th scope="col">
              <div>Enterprise</div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">Number of products/DPPs</th>
            <td>3</td>
            <td>50</td>
            <td>300</td>
            <td>Custom</td>
          </tr>
          <tr>
            <th scope="row">DPP Storage</th>
            <td>Cloud</td>
            <td>Cloud</td>
            <td>Cloud</td>
            <td>
              Cloud,
              <br />
              Blockchain
            </td>
          </tr>
          <tr>
            <td
              colSpan="5"
              class="gradient-callout-cousteau white-1 small mt-1 p-1"
            >
              DPP Planner
            </td>
          </tr>
          <tr>
            <th scope="row">DPP Planner</th>
            <td>
              <Check />
            </td>
            <td>
              <Check />
            </td>
            <td>
              <Check />
            </td>
            <td>
              <Check />
            </td>
          </tr>
          <tr>
            <th scope="row">
              Pre-defined DPP product templates aligned to EU requirements
            </th>
            <td>
              <Check />
            </td>
            <td>
              <Check />
            </td>
            <td>
              <Check />
            </td>
            <td>
              <Check />
            </td>
          </tr>
          <tr>
            <th scope="row">Sustainability attribute library</th>
            <td>
              <Check />
            </td>
            <td>
              <Check />
            </td>
            <td>
              <Check />
            </td>
            <td>
              <Check />
            </td>
          </tr>
          <tr>
            <th scope="row">DPP header media types</th>
            <td>Image</td>
            <td>Image</td>
            <td>
              Image,
              <br />
              Video
            </td>
            <td>
              Image,
              <br />
              Video
            </td>
          </tr>
          <tr>
            <th scope="row">Save company-specific DPP product templates</th>
            <td></td>
            <td></td>
            <td>
              <Check />
            </td>
            <td>
              <Check />
            </td>
          </tr>
          <tr>
            <th scope="row">
              Add custom categories / attributes to DPP templates
            </th>
            <td></td>
            <td></td>
            <td>
              <Check />
            </td>
            <td>
              <Check />
            </td>
          </tr>
          <tr>
            <th scope="row">
              DPP Planner Elements: Use repeatable groups of sustainability
              attributes across products in DPP Planner to simplify data
              management
            </th>
            <td></td>
            <td></td>
            <td>
              <Check />
            </td>
            <td>
              <Check />
            </td>
          </tr>
          <tr>
            <td
              colSpan="5"
              class="gradient-callout-cousteau white-1 small mt-1 p-1"
            >
              AI Assistant
            </td>
          </tr>
          <tr>
            <th scope="row">
              Summarize sustainablity data from Web URLs, Word docs, and
              PowerPoint presentations
            </th>
            <td></td>
            <td></td>
            <td>
              <Check />
            </td>
            <td>
              <Check />
            </td>
          </tr>
          <tr>
            <th scope="row">
              Map automatic summaries into sections (like materials origin,
              carbon footprint, energy usage, recycling, and more) based on DPP
              templates
            </th>
            <td></td>
            <td></td>
            <td>
              <Check />
            </td>
            <td>
              <Check />
            </td>
          </tr>
          <tr>
            <th scope="row">
              Use custom, follow-on AI prompts to refine generated output,
              including focusing on specific product attributes
            </th>
            <td></td>
            <td></td>
            <td>
              <Check />
            </td>
            <td>
              <Check />
            </td>
          </tr>
          <tr>
            <th scope="row">
              Fine-tune options used in generating AI content, including target
              word count, output variety, and content repetition.
            </th>
            <td></td>
            <td></td>
            <td>
              <Check />
            </td>
            <td>
              <Check />
            </td>
          </tr>
          <tr>
            <th scope="row">
              Manually review AI-generated content for accuracy, and edit
              information before publishing.{" "}
            </th>
            <td></td>
            <td></td>
            <td>
              <Check />
            </td>
            <td>
              <Check />
            </td>
          </tr>
          <tr>
            <th scope="row">
              Utilize the AI Assistant knowing that no customer content is used
              to train the underlying AI model.{" "}
            </th>{" "}
            <td></td>
            <td></td>
            <td>
              <Check />
            </td>
            <td>
              <Check />
            </td>
          </tr>
          <tr>
            <th scope="row">
              Select between multiple foundational large language models for
              text generation, including OpenAI GPT 4o, OpenAI GPT 3.5 Turbo,
              and Google Gemini Flash 1.5.
            </th>{" "}
            <td></td>
            <td></td>
            <td></td>
            <td>Available</td>
          </tr>
          <tr>
            <td
              colSpan="5"
              class="gradient-callout-cousteau white-1 small mt-1 p-1"
            >
              DPP Data
            </td>
          </tr>

          <tr>
            <th scope="row">DPP Planner/DPP Data Events media types</th>
            <td>Text</td>
            <td>
              Text,
              <br />
              Images
              <br />
              Web links
            </td>
            <td>
              Text,
              <br />
              Images,
              <br />
              Web links,
              <br />
              Stock images,
              <br />
              Icon library,
            </td>
            <td>
              Text,
              <br />
              Images,
              <br />
              Web links,
              <br />
              Stock images,
              <br />
              Icon library
            </td>
          </tr>
          {/* <tr>
            <th scope="row">DPP Data Events max.</th>
            <td>5</td>
            <td>20</td>
            <td>200</td>
            <td>Custom</td>
          </tr> */}
          <tr>
            <th scope="row">
              DPP Planner/DPP Data Events character max. (each event)
            </th>
            <td>1000</td>
            <td>2000</td>
            <td>5000</td>
            <td>5000</td>
          </tr>
          <tr>
            <th scope="row">Reported dates</th>
            <td>Published</td>
            <td>Published</td>
            <td>
              Published,
              <br />
              Custom
            </td>
            <td>
              Published,
              <br />
              Custom
            </td>
          </tr>
          <tr>
            <th scope="row">Item- &amp; batch-level DPP support</th>
            <td></td>
            <td></td>
            <td></td>
            <td>Available</td>
          </tr>
          <tr>
            <th scope="row">
              Mark selected DPP Data Events as private/restricted
            </th>
            <td></td>
            <td></td>
            <td></td>
            <td>Available</td>
          </tr>
          <tr>
            <th scope="row">
              Control access to private DPP Data Events via roles
            </th>
            <td></td>
            <td></td>
            <td></td>
            <td>Available</td>
          </tr>
          <tr>
            <td
              colSpan="5"
              class="gradient-callout-cousteau white-1 small mt-1 p-1"
            >
              DPP Publishing
            </td>
          </tr>
          <tr>
            <th scope="row">
              Instantly render DPP with turnkey, pre-formatted viewer
            </th>
            <td>
              <Check />
            </td>
            <td>
              <Check />
            </td>
            <td>
              <Check />
            </td>
            <td>
              <Check />
            </td>
          </tr>
          <tr>
            <th scope="row">Access DPP QR code</th>
            <td>
              <Check />
            </td>
            <td>
              <Check />
            </td>
            <td>
              <Check />
            </td>
            <td>
              <Check />
            </td>
          </tr>
          <tr>
            <th scope="row">Create DPP short link</th>
            <td>
              <Check />
            </td>
            <td>
              <Check />
            </td>
            <td>
              <Check />
            </td>
            <td>
              <Check />
            </td>
          </tr>
          <tr>
            <th scope="row">Configure DPP sort &amp; display options</th>
            <td>
              <Check />
            </td>
            <td>
              <Check />
            </td>
            <td>
              <Check />
            </td>
            <td>
              <Check />
            </td>
          </tr>
          <tr>
            <th scope="row">DPP Token Collections</th>
            <td></td>
            <td></td>
            <td>
              <Check />
            </td>
            <td>
              <Check />
            </td>
          </tr>
          <tr>
            <th scope="row">Create custom QR codes</th>
            <td></td>
            <td></td>
            <td>
              <Check />
            </td>
            <td>
              <Check />
            </td>
          </tr>
          <tr>
            <th scope="row">Customize category display labels</th>
            <td></td>
            <td></td>
            <td>
              <Check />
            </td>
            <td>
              <Check />
            </td>
          </tr>
          <tr>
            <td
              colSpan="5"
              class="gradient-callout-cousteau white-1 small mt-1 p-1"
            >
              Data &amp; User Management
            </td>
          </tr>
          <tr>
            <th scope="row">Available data centers for user data</th>
            <td>
              Europe,
              <br />
              North America
            </td>
            <td>
              Europe,
              <br />
              North America
            </td>
            <td>
              Europe,
              <br />
              North America
            </td>
            <td>
              Europe,
              <br />
              North America
            </td>
          </tr>
          <tr>
            <th scope="row">Users</th>
            <td>1</td>
            <td>1</td>
            <td>5</td>
            <td>Custom</td>
          </tr>
          <tr>
            <th scope="row">Customize user access with roles</th>
            <td></td>
            <td></td>
            <td>
              <Check />
            </td>
            <td>
              <Check />
            </td>
          </tr>
          <tr>
            <th scope="row">
              Use API to display DPP in custom Web sites, including DPP data,
              private events, item/batch-level data &amp; token collections
            </th>
            <td></td>
            <td></td>
            <td></td>
            <td>Available</td>
          </tr>
          <tr>
            <td
              colSpan="5"
              class="gradient-callout-cousteau white-1 small mt-1 p-1"
            >
              Support
            </td>
          </tr>
          <tr>
            <th scope="row">Support articles &amp; tutorials</th>
            <td>
              <Check />
            </td>
            <td>
              <Check />
            </td>
            <td>
              <Check />
            </td>
            <td>
              <Check />
            </td>
          </tr>
          <tr>
            <th scope="row">Submit support tickets</th>
            <td></td>
            <td>
              <Check />
            </td>
            <td>
              <Check />
            </td>
            <td>
              <Check />
            </td>
          </tr>
          <tr>
            <th scope="row">Custom support plans</th>
            <td></td>
            <td></td>
            <td></td>
            <td>Available</td>
          </tr>
          <tr>
            <th scope="row">Custom training</th>
            <td></td>
            <td></td>
            <td></td>
            <td>Available</td>
          </tr>
          <tr>
            <td
              colSpan="5"
              class="gradient-callout-cousteau white-1 small mt-1 p-1"
            >
              Blockchain modules
            </td>
          </tr>
          <tr>
            <th scope="row">Blockchain smart contract</th>
            <td></td>
            <td></td>
            <td></td>
            <td>Available</td>
          </tr>
          <tr>
            <th scope="row">Role-based blockchain-level permissions</th>
            <td></td>
            <td></td>
            <td></td>
            <td>Available</td>
          </tr>
          <tr>
            <td
              colSpan="5"
              class="gradient-callout-cousteau white-1 small mt-1 p-1"
            >
              Add-on modules
            </td>
          </tr>
          <tr>
            <th scope="row">Loyalty</th>
            <td></td>
            <td></td>
            <td></td>
            <td>Available</td>
          </tr>
          <tr>
            <th scope="row">Communities</th>
            <td></td>
            <td></td>
            <td></td>
            <td>Available</td>
          </tr>
        </tbody>
      </table>
    </div>
  </>
);

export default Snippet;
