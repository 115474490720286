//React
import React, { useState } from "react";

//Gatsby
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// //Packages
import moment from "moment";

// // import { MDXRenderer } from 'gatsby-plugin-mdx'

// //Components
import Layout from "../components/layout";
// import { Metatags } from "../components/marketing/metatags";

import NavSubIndustries from "../components/nav/navSubIndustries";
import FeatureQuad from "../components/marketing/featureQuad";
import BlogCallout from "../components/marketing/blogCallout";
import IndustryImages, {
  useImageData,
} from "../components/marketing/industryImages";

// //Icons
// import { MdPublic } from "react-icons/md";
// import { FaArrowRight } from "react-icons/fa";
// import { select } from "async";

const renderAttributeElement = (
  category,
  attribute,
  showCategory,
  pageContext
) => {
  return (
    <>
      {showCategory && category && category.category ? (
        <div class="fw-bold text-start">{category?.category}</div>
      ) : null}
      <div class={`${!category?.id ? "me-1" : ""} text-start`}>
        {attribute.title?.replaceAll(
          "product",
          pageContext?.data?.node?.mkt?.noun
        )}
      </div>
    </>
  );
};
const renderAttributeSampleList = (attributes, attributeList, limit = 3) => {
  return `${attributes
    .map((attribute, i) => {
      if (i <= limit) {
        return `${i === limit ? ", and " : i === 0 ? " " : ", "}${attributeList
          .find((foundAttribute) => foundAttribute.id === attribute.id)
          ?.category?.category?.toLowerCase()}`;
      } else {
        return "";
      }
    })
    .join("")}`;
};
const renderProductExamples = (pageContext) => {
  return `${pageContext?.data?.node?.mkt?.exampleProducts.join(", ")}, and ${
    pageContext?.data?.node?.mkt?.exampleProductGeneric
  }`;
};

// export default function Template({ data, title, pageContext }) {
//   return <div>industry</div>;
// }

export default function Template({ data, title, pageContext }) {
  console.log("pageContext");
  console.log(pageContext);
  const [showAllAttributes, setShowAllAttributes] = useState(false);

  const rawBenefits = [
    {
      id: 1,
      title: "Increased trust",
      description:
        "Increase trust with customers by detailing your sustainability efforts",
    },
    {
      id: 2,
      title: "Traceable sourcing",
      description: `Show the provenance and origin of your ${pageContext?.data?.node?.mkt?.noun} materials`,
    },
    {
      id: 3,
      title: "Enhanced transparency",
      description: `Elevate transparency about responsible product sourcing and manufacturing`,
    },
    {
      id: 4,
      title: "Regulatory compliance",
      description:
        "Drive compliance with E.U. and global regulations around transparency",
    },
    {
      id: 5,
      title: "Social impact",
      description: `Show how you're driving social impact through your sustainability programs`,
    },
    {
      id: 6,
      title: "Product authentication",
      description: `Verify the sourcing and distribution of your product, and thwart product piracy and counterfeiting`,
    },
    {
      id: 7,
      title: "Circular economy",
      description: `Drive a circular economy, in which products are sustainability sourced, and responsibly recycled`,
    },
    {
      id: 8,
      title: "Customer engagement",
      description: `Deliver additional offers, usage inspiration, and brand content to customers`,
    },
    {
      id: 9,
      title: "Usage instructions",
      description: `Provide info related to product care, maintenance, and usage instructions`,
    },
    {
      id: 10,
      title: "Enhance recycling",
      description: `Deliver info on recycling locations, enable automated sorting, and identify remanufacturing materials`,
    },
  ];

  const featureData = {
    title: `Benefits: Digital Product Passport for ${pageContext.title}`,
    titlecolor: "black-1 my-4 pb-4",
    mode: "trio",
    bggradient: "gradient-callout-silver rounded-corners",
    rowdata: [
      {
        // rowbgcolor: "green",
        rowdata: rawBenefits
          .filter((arrayItem) =>
            pageContext?.data?.node?.mkt?.benefits?.includes(arrayItem.id)
          )
          .map((arrayItem, i) => ({
            ...arrayItem,
            colbgcolor: `blue-${i % 2 === 0 ? "2" : "1"}`,
          })),
      },
    ],
  };

  const sustainabilityCopy = [
    {
      category: 1,
      treatment: 1,
      title: `Show sustainability info`,
      desc: `A Digital Product Passport (DPP) for ${
        pageContext.title
      } shows sustainability information about ${renderProductExamples(
        pageContext
      )}. They help customers make educated decisions about the products they buy.`,
    },
    {
      category: 1,
      treatment: 2,
      title: `Enhance product sustainability`,
      desc: `Digital Product Passports (DPPs) for ${
        pageContext.title
      } surface important details about products - including for ${renderProductExamples(
        pageContext
      )} - so that customers can make informed decisions about what to buy.`,
    },
    {
      category: 1,
      treatment: 3,
      title: `Support a circular economy`,
      desc: `Digital Product Passports for ${pageContext.title} help drive a circular economy by delivering product-level sustainability data to customers.`,
    },
    {
      category: 2,
      treatment: 1,
      title: `Access ${pageContext.title?.toLowerCase()} DPPs via QR Code`,
      desc: `Companies can provides customers a QR code - either on the product itself or in accompanying materials - which can be scanned to display a Digital Product Passport for ${pageContext.title}.`,
    },
    {
      category: 2,
      treatment: 2,
      title: `View DPPs using QR Codes`,
      desc: `Customers can access DPPs for ${pageContext.title} by scanning a QR code with their smartphones, and can view product information.`,
    },
    {
      category: 2,
      treatment: 3,
      title: `Make informed purchasing decisions`,
      desc: `By scanning a QR code, customers can view data on ${renderProductExamples(
        pageContext
      )} - and can make more informed purchasing decisions when considering different products.`,
    },
    {
      category: 3,
      treatment: 1,
      title: `Drive transparency & compliance`,
      desc: `Creating DPPs for ${pageContext.title} helps companies drive greater transparency with their products, increase customer trust, and comply with E.U. regulations.`,
    },
    {
      category: 3,
      treatment: 2,
      title: `Avoid greenwashing`,
      desc: `Digital Product Passports for ${pageContext.title} help brands avoid greenwashing, and builds greater trust with customers when they can see details about product origin.`,
    },
    {
      category: 3,
      treatment: 3,
      title: `Increase customer trust`,
      desc: `Digital Product Passports for ${pageContext.title} help companies better connect with their customers about sustainability, and provide transparency into their supply chains, manufacturing, and social impact.`,
    },
  ];

  const staticCopyItem = {
    category: 4,
    treatment: 1,
    title: `Provide sustainability data for ${pageContext.title?.toLowerCase()}`,
    desc: `${
      pageContext.title
    } DPPs can include product-level data about ${renderAttributeSampleList(
      pageContext.data.node.attributes,
      pageContext.data.attributeList
    )}.`,
  };

  const findCopyItem = (pos) => {
    return sustainabilityCopy.find(
      (copyItem) =>
        copyItem.category === pos &&
        copyItem.treatment ===
          pageContext?.data?.node?.mkt?.copyTreatment?.[pos - 1]
    );
  };
  const selectedCopy = [
    findCopyItem(1),
    findCopyItem(2),
    staticCopyItem,
    findCopyItem(3),
  ];
  let copyOrder;

  if (pageContext?.data?.pos % 9 === 0) {
    copyOrder = [0, 2, 1, 3];
  } else if (pageContext?.data?.pos % 8 === 0) {
    copyOrder = [3, 1, 2, 0];
  } else if (pageContext?.data?.pos % 7 === 0) {
    copyOrder = [1, 3, 2, 0];
  } else if (pageContext?.data?.pos % 6 === 0) {
    copyOrder = [0, 3, 1, 2];
  } else if (pageContext?.data?.pos % 5 === 0) {
    copyOrder = [1, 2, 3, 0];
  } else if (pageContext?.data?.pos % 4 === 0) {
    copyOrder = [2, 3, 0, 1];
  } else if (pageContext?.data?.pos % 3 === 0) {
    copyOrder = [3, 2, 1, 0];
  } else if (pageContext?.data?.pos % 2 === 0) {
    copyOrder = [2, 1, 0, 3];
  } else {
    copyOrder = [0, 1, 2, 3];
  }
  const attributeList = showAllAttributes
    ? pageContext.data.node.attributes
    : pageContext.data.node.attributes.filter((attribute, i) => i <= 4);
  return (
    <Layout>
      <NavSubIndustries
        sections={data?.industries?.edges.map((industry) => ({
          name: industry.node.pageContext.title,
          path: industry.node.path,
        }))}
      />
      <div class="container container-page container-about-sub">
        <div class="row">
          <div class="col-md-4 d-flex flex-column align-items-center justify-content-center text-center text-lg-center">
            <div class="d-flex align-items-start justify-content-center">
              {/* <StaticImage
              src="../images/featureperspective/passport-usecases/fashionnetwork-passport-shoe-R.png"
              alt="PicoNext"
              placeholder="blurred"
              layout="constrained"
              width={275}
              height={400}
              transformOptions={{ cropFocus: 'north' }}
            /> */}
              {/* <div class="industry-header-image"> */}
              <IndustryImages
                industry={pageContext?.title
                  ?.toLowerCase()
                  ?.replaceAll(" ", "")}
                size="Lg"
                output="image"
              />
              {/* </div> */}
            </div>
          </div>
          <div class="col-md-8 d-flex flex-column align-items-center justify-content-center text-center text-lg-center">
            <h1 class="fw-normal display-2 page-title black-1 mt-2 mt-md-0 pt-4 pt-md-0">
              Digital Product Passport for {pageContext.title}
            </h1>

            <p class="display-4 line-height-4 p-2">
              Drive sustainability transparency for{" "}
              {renderProductExamples(pageContext)}
            </p>

            <Link
              to={`/demo/video/?c=${pageContext.title
                ?.toLowerCase()
                ?.replaceAll(" ", "")}`}
              class="btn btn-outline-primary"
            >
              See demo &rarr;
              {/* <FaArrowRight style={{ marginTop: -3 }} /> */}
            </Link>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-12 d-flex flex-column justify-content-center text-center text-lg-center">
            {/* <h1 class="display-2 fw-medium blue-1">
            Digital Product Passport for {pageContext.title}
          </h1>
          <p class="display-4">
            The quick brown fox jumps over the lazy dog.
          </p> */}
            <hr size={1} class="my-5" />
            <div class="gradient-callout-silver-reverse mb-3 rounded-corners">
              <h2 class="h3 text-center text-lg-center mt-2 mb-5 black-4">
                What is a Digital Product Passport (DPP) for {pageContext.title}
                ?
              </h2>
              <div class="row mb-4 pb-2">
                <div class="col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
                  {copyOrder.map((pos) => (
                    <div class="p-2">
                      <div class="h4 green-1">{selectedCopy[pos]?.title}</div>
                      <p class="lead line-height-3">
                        {selectedCopy[pos]?.desc}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {/* <hr size={1} class="my-5" /> */}
            <div class="my-3">
              <h2 class="h3 text-center text-lg-center my-4 pb-4 black-4">
                {pageContext.title} DPP Example Data
              </h2>
              {/* BEGIN TIMELINE */}
              <div class="d-flex flex-column container-token-events round-corners-top">
                {attributeList.map((attribute, i) => (
                  <div
                    class={`d-flex flex-row ${
                      i === 0 && attributeList.length === 1
                        ? "token-events-row-start token-events-row-only"
                        : i === 0
                        ? "token-events-row-start"
                        : attributeList.length === i + 1
                        ? "token-events-row-end"
                        : "token-events-row-mid"
                    } `}
                  >
                    <div
                      class={`col-1 d-flex flex-column align-items-center  ${
                        i === 0
                          ? "justify-content-end"
                          : attributeList.length === i + 1
                          ? "justify-content-start"
                          : "justify-content-start"
                      }`}
                    >
                      <div
                        class={`token-event-timeline d-flex flex-column align-items-start ${
                          i === 0 && i + 1 === attributeList.length
                            ? "token-event-timeline-nobg"
                            : ""
                        }  ${
                          i === 0
                            ? "justify-content-start"
                            : attributeList.length === i + 1
                            ? "justify-content-end"
                            : "justify-content-center"
                        }`}
                      >
                        <div class="token-event-timeline-marker-start"></div>
                      </div>
                    </div>
                    <div class="col-11 d-flex flex-column align-items-start justify-content-start  border-1-gray-6 rounded-corners py-1 my-1">
                      <div class="d-flex flex-row align-items-start justify-content-start w-100">
                        {/* <div class="me-1 mb-1" style={{ width: 30 }}>
                          <MdPublic size={20} class="gray-1" />
                        </div> */}
                        <div class="fw-light line-height-1 ledger-text mt-1 mb-2 p-2">
                          {/* {item.title} */}
                          {/* {JSON.stringify(attribute)} */}
                          {renderAttributeElement(
                            pageContext.data.attributeList.find(
                              (foundAttribute) =>
                                foundAttribute.id === attribute.id
                            )?.category,
                            {
                              id: attribute.id,
                              title: pageContext.data.attributeList.find(
                                (foundAttribute) =>
                                  foundAttribute.id === attribute.id
                              )?.title,
                            },
                            true,
                            pageContext
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <button
                class="mt-3 btn btn-outline-sky"
                onClick={() => {
                  setShowAllAttributes(!showAllAttributes);
                }}
              >
                Show {showAllAttributes ? "top" : "all"} attributes for{" "}
                {pageContext?.title?.toLowerCase()}
              </button>
              {/* END TIMELINE */}
            </div>
            {/* <hr size={1} class="my-5" /> */}
            {/* <h2 class="h3 text-center text-lg-center my-4 black-4">
            DPP Benefits for {pageContext.title}
          </h2> */}
            <div class="my-4 py-4">
              <FeatureQuad featureData={featureData} />

              <Link
                to={`/article/digital-product-passport/`}
                class="btn btn-outline-dkblue"
              >
                Learn more about Digital Product Passports &rarr;
                {/* <FaArrowRight style={{ marginTop: -3 }} /> */}
              </Link>
            </div>

            <BlogCallout
              context={`industry${pageContext.title
                ?.toLowerCase()
                ?.replaceAll(" ", "")}`}
              title={`${pageContext.title} Resources`}
              desc={`Articles to help you get started with Digital Product Passports for ${pageContext.title?.toLowerCase()}`}
              showMoreButton={false}
              containerClass="gradient-callout-silver rounded-corners"
              headerClass="h3"
            />

            <hr size={1} class="pb-3" />

            <div class="row">
              <div class="col-md-4 d-flex flex-column align-items-center justify-content-center text-center text-lg-center">
                <div
                  class="d-flex align-items-start justify-content-center"
                  // style={{ minHeight: 580 }}
                >
                  <StaticImage
                    src="../images/featureperspective/passport-usecases/passport-planner-attribute-editor-R.png"
                    alt="PicoNext"
                    placeholder="blurred"
                    layout="constrained"
                    width={275}
                    height={400}
                    transformOptions={{ cropFocus: "north" }}
                  />
                </div>
              </div>
              <div class="col-md-8 d-flex flex-column align-items-center justify-content-center text-center text-lg-center">
                <h2 class="h3 text-center text-lg-center my-4 black-4">
                  Get started with a Digital Product Passport for{" "}
                  {pageContext.title}
                </h2>
                <p class="display-4 line-height-4 p-2">
                  Gather sustainability data and prepare your Digital Product
                  Passport for {pageContext.title} using a no-charge,
                  template-based tool
                </p>
                {/* <Link
                  to={`/request-invite-dpp-planner/?c=${pageContext.title
                    ?.toLowerCase()
                    ?.replaceAll(" ", "")}`}
                  class="btn btn-outline-primary"
                >
                  Request free access to DPP Planner &rarr;
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr size={1} class="my-2" />
    </Layout>
  );
}

export const Head = ({ data, title, pageContext }) => {
  return (
    <>
      <title>Digital Product Passport for {pageContext.title} - PicoNext</title>
      <meta
        name="description"
        content={`Discover how to create a Digital Product Passport for ${pageContext.title} to drive sustainability transparency, comply with new regulations, and increase customer trust`}
      />
      <meta
        name="keywords"
        content={`Digital Product Passport, ${pageContext.title}, DPP, European Union, Ecodesign for Sustainable Products Regulation, sustainability, carbon neutral, climate, supply chain`}
      />
      <meta property="og:site_name" content={"PicoNext"} />
      <meta
        property="og:title"
        content={`Digital Product Passport for ${pageContext.title} - PicoNext`}
      />
      <meta name="author" content={"PicoNext"} />
      <meta property="og:type" content={"article"} />

      <meta
        property="og:image"
        content={`http://${process.env.HOSTNAME}${useImageData({
          industry: pageContext?.title?.toLowerCase()?.replaceAll(" ", ""),
          size: "Lg",
          output: "src",
        })}`}
      />
      <meta
        property="og:image:secure_url"
        content={`https://${process.env.HOSTNAME}${useImageData({
          industry: pageContext?.title?.toLowerCase()?.replaceAll(" ", ""),
          size: "Lg",
          output: "src",
        })}`}
      />
      <meta name="twitter:card" content={`summary_large_image`} />
      <meta name="twitter:site" content={`@piconexthq`} />
      <meta
        name="twitter:title"
        content={`Digital Product Passport for ${pageContext.title}`}
      ></meta>
      <meta
        name="twitter:description"
        content={`Discover how to create a Digital Product Passport for ${pageContext.title} to drive sustainability transparency, comply with new regulations, and increase customer trust`}
      ></meta>
      <meta
        name="twitter:image"
        content={`http://${process.env.HOSTNAME}${useImageData({
          industry: pageContext?.title?.toLowerCase()?.replaceAll(" ", ""),
          size: "",
          output: "src",
        })}`}
      />
      <script type="application/ld+json">
        {`
        [{
          "@context": "https://schema.org",
          "@type": "Article",
          "headline": "Digital Product Passport for ${pageContext.title}",
          "image": [
            "http://${process.env.HOSTNAME}${useImageData({
          industry: pageContext?.title?.toLowerCase()?.replaceAll(" ", ""),
          size: "",
          output: "src",
        })}"
           ],
          "datePublished": "${moment
            .unix(pageContext?.data?.node?.datecreated)
            .format("YYYY-MM-DD")}T08:00:00+08:00",
          "dateModified": "${moment
            .unix(pageContext?.data?.node?.dateupdated)
            .format("YYYY-MM-DD")}T08:00:00+08:00",
          "author": [{
              "@type": "Organization",
              "name": "PicoNext",
              "url": "https://piconext.com"
            }]
        },
        {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [{
            "@type": "ListItem",
            "position": 1,
            "name": "Home",
            "item": "http${process.env.STAGE === "dev" ? "" : "s"}://${
          process.env.HOSTNAME
        }"
          },{
            "@type": "ListItem",
            "position": 2,
            "name": "Industries",
            "item": "http${process.env.STAGE === "dev" ? "" : "s"}://${
          process.env.HOSTNAME
        }/industry"
          },{
            "@type": "ListItem",
            "position": 3,
            "name": "${pageContext.title}"
          }]
        }
      ]
        `}
      </script>
    </>
  );
};

export const pageQuery = graphql`
  query {
    industries: allSitePage(
      filter: { path: { glob: "/(industry)/*" } }
      sort: { path: ASC }
    ) {
      edges {
        node {
          path
          pageContext
        }
      }
    }
  }
`;
