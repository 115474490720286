//React
import React from "react";

//Gatsby

import { useStaticQuery, graphql, Link } from "gatsby";

//Actions

//Components
import PostLink from "./postLink";

//Icons

export default function Snippet({
  title,
  desc,
  context,
  containerClass,
  textTheme,
  showMoreButton,
  headerClass,
}) {
  const data = useStaticQuery(graphql`
    query {
      home: allMdx(
        sort: { frontmatter: { date: DESC } }
        filter: {
          frontmatter: {
            path: { glob: "/article/*" }
            publish: { eq: 1 }
            date: {
              in: ["2024-07-08", "2024-05-22", "2024-01-17", "2023-09-05"]
            }
          }
        }
        limit: 4
      ) {
        edges {
          node {
            id
            excerpt(pruneLength: 250)
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              updated(formatString: "MMMM DD, YYYY")
              path
              title
              publish
              externalLink
              featuredImage {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 300)
                }
              }
            }
          }
        }
      }
      homecustomers: allMdx(
        sort: { frontmatter: { sortOrder: ASC } }
        filter: {
          frontmatter: {
            path: { glob: "/customers/*" }
            publish: { eq: 1 }
            sortOrder: { gt: 0 }
          }
        }
        limit: 4
      ) {
        edges {
          node {
            id
            excerpt(pruneLength: 250)
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              dateVal: date(formatString: "YYYY-MM-DD")
              updated(formatString: "MMMM DD, YYYY")
              path
              title
              publish
              externalLink
              featuredImage {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 300)
                }
              }
              tileTitle
              tileTag
              tileImage {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 300)
                }
              }
            }
          }
        }
      }
      demopost: allMdx(
        sort: { frontmatter: { date: DESC } }
        filter: {
          frontmatter: {
            path: { glob: "/article/*" }
            publish: { eq: 1 }
            date: { in: ["2024-04-02", "2023-09-05"] }
          }
        }
        limit: 3
      ) {
        edges {
          node {
            id
            excerpt(pruneLength: 250)
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              updated(formatString: "MMMM DD, YYYY")
              path
              title
              publish
              externalLink
              featuredImage {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 300)
                }
              }
            }
          }
        }
      }
      socialimpact: allMdx(
        sort: { frontmatter: { date: DESC } }
        filter: {
          frontmatter: {
            path: { glob: "/article/*" }
            publish: { eq: 1 }
            date: { in: ["2023-06-06", "2023-11-19", "2022-12-15"] }
          }
        }
        limit: 3
      ) {
        edges {
          node {
            id
            excerpt(pruneLength: 250)
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              updated(formatString: "MMMM DD, YYYY")
              path
              title
              publish
              externalLink
              featuredImage {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 300)
                }
              }
            }
          }
        }
      }
      events: allMdx(
        sort: { frontmatter: { date: DESC } }
        filter: {
          frontmatter: {
            path: { glob: "/article/*" }
            publish: { eq: 1 }
            date: { in: ["2022-08-29", "2022-08-22", "2022-05-19"] }
          }
        }
        limit: 3
      ) {
        edges {
          node {
            id
            excerpt(pruneLength: 250)
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              updated(formatString: "MMMM DD, YYYY")
              path
              title
              publish
              externalLink
              featuredImage {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 300)
                }
              }
            }
          }
        }
      }
      loyalty: allMdx(
        sort: { frontmatter: { date: DESC } }
        filter: {
          frontmatter: {
            path: { glob: "/article/*" }
            publish: { eq: 1 }
            date: { in: ["2023-03-14", "2023-02-23", "2022-11-09"] }
          }
        }
        limit: 3
      ) {
        edges {
          node {
            id
            excerpt(pruneLength: 250)
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              updated(formatString: "MMMM DD, YYYY")
              path
              title
              publish
              externalLink
              featuredImage {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 300)
                }
              }
            }
          }
        }
      }
      industryapparel: allMdx(
        sort: { frontmatter: { date: DESC } }
        filter: {
          frontmatter: {
            path: { glob: "/article/*" }
            publish: { eq: 1 }
            date: { in: ["2024-01-17", "2024-04-25", "2023-09-05"] }
          }
        }
        limit: 4
      ) {
        edges {
          node {
            id
            excerpt(pruneLength: 250)
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              updated(formatString: "MMMM DD, YYYY")
              path
              title
              publish
              externalLink
              featuredImage {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 300)
                }
              }
            }
          }
        }
      }
      industrybatteries: allMdx(
        sort: { frontmatter: { date: DESC } }
        filter: {
          frontmatter: {
            path: { glob: "/article/*" }
            publish: { eq: 1 }
            date: {
              in: ["2024-04-25", "2024-02-20", "2024-02-14", "2023-09-05"]
            }
          }
        }
        limit: 4
      ) {
        edges {
          node {
            id
            excerpt(pruneLength: 250)
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              updated(formatString: "MMMM DD, YYYY")
              path
              title
              publish
              externalLink
              featuredImage {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 300)
                }
              }
            }
          }
        }
      }
      industryconstructionmaterials: allMdx(
        sort: { frontmatter: { date: DESC } }
        filter: {
          frontmatter: {
            path: { glob: "/article/*" }
            publish: { eq: 1 }
            date: { in: ["2024-03-01", "2024-04-25", "2023-09-05"] }
          }
        }
        limit: 4
      ) {
        edges {
          node {
            id
            excerpt(pruneLength: 250)
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              updated(formatString: "MMMM DD, YYYY")
              path
              title
              publish
              externalLink
              featuredImage {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 300)
                }
              }
            }
          }
        }
      }
      industrychemicals: allMdx(
        sort: { frontmatter: { date: DESC } }
        filter: {
          frontmatter: {
            path: { glob: "/article/*" }
            publish: { eq: 1 }
            date: { in: ["2023-09-05", "2024-04-25", "2024-02-14"] }
          }
        }
        limit: 4
      ) {
        edges {
          node {
            id
            excerpt(pruneLength: 250)
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              updated(formatString: "MMMM DD, YYYY")
              path
              title
              publish
              externalLink
              featuredImage {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 300)
                }
              }
            }
          }
        }
      }
      industrycosmetics: allMdx(
        sort: { frontmatter: { date: DESC } }
        filter: {
          frontmatter: {
            path: { glob: "/article/*" }
            publish: { eq: 1 }
            date: { in: ["2024-04-25", "2024-02-14", "2023-09-05"] }
          }
        }
        limit: 4
      ) {
        edges {
          node {
            id
            excerpt(pruneLength: 250)
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              updated(formatString: "MMMM DD, YYYY")
              path
              title
              publish
              externalLink
              featuredImage {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 300)
                }
              }
            }
          }
        }
      }
      industryelectronics: allMdx(
        sort: { frontmatter: { date: DESC } }
        filter: {
          frontmatter: {
            path: { glob: "/article/*" }
            publish: { eq: 1 }
            date: { in: ["2024-04-25", "2024-02-14", "2023-09-05"] }
          }
        }
        limit: 4
      ) {
        edges {
          node {
            id
            excerpt(pruneLength: 250)
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              updated(formatString: "MMMM DD, YYYY")
              path
              title
              publish
              externalLink
              featuredImage {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 300)
                }
              }
            }
          }
        }
      }
      industryfootwear: allMdx(
        sort: { frontmatter: { date: DESC } }
        filter: {
          frontmatter: {
            path: { glob: "/article/*" }
            publish: { eq: 1 }
            date: { in: ["2024-01-17", "2024-04-25", "2023-09-05"] }
          }
        }
        limit: 4
      ) {
        edges {
          node {
            id
            excerpt(pruneLength: 250)
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              updated(formatString: "MMMM DD, YYYY")
              path
              title
              publish
              externalLink
              featuredImage {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 300)
                }
              }
            }
          }
        }
      }
      industryfurniture: allMdx(
        sort: { frontmatter: { date: DESC } }
        filter: {
          frontmatter: {
            path: { glob: "/article/*" }
            publish: { eq: 1 }
            date: { in: ["2024-02-14", "2024-04-25", "2023-09-05"] }
          }
        }
        limit: 4
      ) {
        edges {
          node {
            id
            excerpt(pruneLength: 250)
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              updated(formatString: "MMMM DD, YYYY")
              path
              title
              publish
              externalLink
              featuredImage {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 300)
                }
              }
            }
          }
        }
      }
      industrymattresses: allMdx(
        sort: { frontmatter: { date: DESC } }
        filter: {
          frontmatter: {
            path: { glob: "/article/*" }
            publish: { eq: 1 }
            date: { in: ["2024-04-25", "2024-02-28", "2023-09-05"] }
          }
        }
        limit: 4
      ) {
        edges {
          node {
            id
            excerpt(pruneLength: 250)
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              updated(formatString: "MMMM DD, YYYY")
              path
              title
              publish
              externalLink
              featuredImage {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 300)
                }
              }
            }
          }
        }
      }
      industryplastics: allMdx(
        sort: { frontmatter: { date: DESC } }
        filter: {
          frontmatter: {
            path: { glob: "/article/*" }
            publish: { eq: 1 }
            date: { in: ["2023-09-05", "2024-02-14", "2024-04-25"] }
          }
        }
        limit: 4
      ) {
        edges {
          node {
            id
            excerpt(pruneLength: 250)
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              updated(formatString: "MMMM DD, YYYY")
              path
              title
              publish
              externalLink
              featuredImage {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 300)
                }
              }
            }
          }
        }
      }
      industrytextiles: allMdx(
        sort: { frontmatter: { date: DESC } }
        filter: {
          frontmatter: {
            path: { glob: "/article/*" }
            publish: { eq: 1 }
            date: { in: ["2024-01-17", "2023-09-05", "2024-04-25"] }
          }
        }
        limit: 4
      ) {
        edges {
          node {
            id
            excerpt(pruneLength: 250)
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              updated(formatString: "MMMM DD, YYYY")
              path
              title
              publish
              externalLink
              featuredImage {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 300)
                }
              }
            }
          }
        }
      }
    }
  `);

  console.log("blogCallout - data");
  console.log(data);
  return (
    <div
      class={`${
        containerClass ? containerClass : "gradient-callout-silver"
      } my-2 pt-2 pb-4`}
    >
      <div class="container">
        <div class="row d-flex">
          <div class="col-md-12 rounded-corners text-center p-2 py-4">
            {/* <div class="d-flex justify-content-center align-items-center mb-3">
            <FeatureIcon context="coin" />
          </div> */}
            <h2
              class={`${textTheme === "light" ? "white-1" : "black-1"} ${
                headerClass ? headerClass : ""
              } fw-bold py-2`}
            >
              {title}
            </h2>
            <p
              class={`lead text-center display-4 ${
                textTheme === "light" ? "white-3" : "black-3"
              }`}
            >
              {desc}
            </p>
            <div className="row d-flex flex-row align-items-stretch justify-content-center">
              {data?.[context]?.edges.map((edge, i) => (
                <PostLink home={true} key={edge.node.id} post={edge.node} />
              ))}
            </div>
            {showMoreButton && (
              <Link
                to="/resources/"
                class={`btn ${
                  textTheme === "light"
                    ? "btn-cta-outline"
                    : "btn-outline-dkblue"
                } ${context === "home" ? "fw-bold" : ""} mt-4`}
              >
                <div>
                  Explore all resources &rarr;
                  {/* <FaArrowRight class="btn-arrow" /> */}
                </div>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
