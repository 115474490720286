//React
import React from "react";

//Gatsby
import { graphql, Link } from "gatsby";

//Components
import Layout from "../../components/layout";
import { Metatags } from "../../components/marketing/metatags";

import AlternatingCallout from "../../components/marketing/alternatingCallout";
// import PostLink from "../../components/marketing/postLink";
import FeatureLink from "../../components/marketing/featureLink2";
import YoutubeComponent from "../../components/marketing/youtubeComponent";

//Icons
// import { FaArrowRight } from "react-icons/fa";

const useCaseData = [
  {
    context: "Data carrier",
    contextColor: "yellow-1",
    title: "Access sustainability attributes via QR Code",
    description:
      "Provide a QR code that allows customers to access product lifecycle sustainability details - including origin, recyclability, carbon footprint and more",
    image: "productPassportQR",
    iconShow: false,
    // //iconContext: 'imagespread',
    iconColor: "blue",
    iconcolor: "white",
    iconfill: "white",
    lineClass: "gray-5",
    anchor: "images",
    // videosource: 'console-1',
  },

  {
    context: "Data storage",
    contextColor: "pink-1",
    title: "Store sustainability data on blockchains or the cloud",
    description:
      "Easily store product sustainability data on public blockchains or the cloud - delivering transparency improving customer trust",
    image: "productPassportMintingAlt",
    iconShow: false,
    // //iconContext: 'iphone',
    iconColor: "sky",
    iconcolor: "white",
    iconfill: "white",
    lineClass: "gray-5",
    anchor: "mobile",
    // videosource: 'console-2',
  },
  {
    context: "Generative AI",
    contextColor: "dkgreen-1",
    title: "Accelerate DPP workflows with generative AI",
    description:
      "Use generative AI to summarize complex, raw sustainability data into Digital Product Passports",
    image: "nft8FeatureOverview",
    iconShow: false,
    // //iconContext: 'iphone',
    iconColor: "sky",
    iconcolor: "white",
    iconfill: "white",
    lineClass: "gray-3",
    anchor: "mobile",
    // videosource: 'console-2',
  },
  {
    context: "Digital Product Passport Planner",
    contextColor: "green-1",
    title: "Gather DPP data using templates",
    description:
      "Gather sustainability data, manage products, and prepare your Digital Product Passport using a no-charge, template-based planning tool",
    image: "nft5FeatureOverview",
    iconShow: false,
    // //iconContext: 'iphone',
    iconColor: "dkgreen",
    iconcolor: "white",
    iconfill: "white",
    lineClass: "gray-5",
    anchor: "mobile",
    // videosource: 'console-3',
  },
  // {
  //   context: 'Data templates',
  //   contextColor: 'green-1',
  //   title: 'Accelerate workflows with data templates',
  //   description:
  //     'Use predefined data templates for your product / industry to communicate product sustainability information and speed your workflow',
  //   image: 'productPassportTokenEvent',
  //   iconShow: false,
  //   // //iconContext: 'iphone',
  //   iconColor: 'dkgreen',
  //   iconcolor: 'white',
  //   iconfill: 'white',
  //   lineClass: 'gray-5',
  //   anchor: 'mobile',
  //   // videosource: 'console-3',
  // },

  {
    context: "Customer experience",
    contextColor: "sky-1",
    title: "Augment passport info with customer experiences",
    description:
      "Connect deeper with customers through additional experiences when they access your passport, including loyalty rewards and communities",
    image: "productPassportWalletExplorerAlt",
    iconShow: false,
    // //iconContext: 'iphone',
    iconColor: "sky",
    iconcolor: "white",
    iconfill: "white",
    lineClass: "gray-3",
    anchor: "mobile",
    // videosource: 'console-2',
  },
  {
    context: "Regulatory compliance",
    contextColor: "yellow-1",
    title: "Comply with EU digital product passport regulations",
    description:
      "Drive compliance with EU regulations about product lifecycle sustainabilty by providing QR code-enabled access to product data stored on public blockchains",
    image: "productPassportLifestyleAlt",
    iconShow: false,
    // //iconContext: 'iphone',
    iconColor: "yellow",
    iconcolor: "white",
    iconfill: "white",
    // lineClass: 'gray-3',
    anchor: "mobile",
    // videosource: 'console-2',
  },
  // {
  //   context: 'Authentication & Resale',
  //   contextColor: 'dkorange-1',
  //   title: 'Authenticate tickets and benefit from resale',
  //   description:
  //     'Verify event tickets as authentic and participate in secondary resale commissions through programmable smart contracts',
  //   image: 'nft3EventB',
  //   iconShow: false,
  //   // //iconContext: 'iphone',
  //   iconColor: 'dkorange',
  //   iconcolor: 'white',
  //   iconfill: 'white',
  //   // lineClass: 'gray-3',
  //   anchor: 'mobile',
  //   // videosource: 'console-3',
  // },
  // {
  //   title: 'Publish across channels',
  //   description:
  //     'From PicoNext, publish your videos across channels and meet your prospective employees where they are - including on TikTok, Instagram Stories and more',
  //   image: 'featureVideoSharing',
  //   iconShow: true,
  //   // //iconContext: 'imagespread',
  //   iconColor: 'dkorange',
  //   iconcolor: 'white',
  //   iconfill: 'white',
  //   lineClass: 'red-1',
  //   anchor: 'images',
  // },
  // {
  //   context: 'Solution',
  //   contextColor: 'dkgreen-1',

  //   title: 'Social design',
  //   description:
  //     "PicoNext helps you drive revenue through consistent engagement with your customers. It's easy to discover designers with fresh content templates you can remix to drive your business - no matter where you are.",
  //   image: 'usecaseRestaurantScreenImage',
  //   iconShow: false,
  //   //iconContext: 'lightning',
  //   iconColor: 'dkgreen',
  //   iconcolor: 'white',
  //   iconfill: 'white',
  //   // lineClass: 'dkorange-1',
  //   anchor: 'share',
  // },
];
const featureData = {
  title: "Benefits of creating NFTs using PicoNext",
  titlecolor: "white-1",
  mode: "trio",
  bggradient: "gradient-callout-overcast",
  rowdata: [
    {
      // rowbgcolor: "green",
      rowdata: [
        {
          title: "Sustainability data",
          colbgcolor: "sky-1",
          iconname: "award",
          description:
            "Gather product sustainability data, and prepare product media",
        },
        {
          title: "Blockchain data storage",
          colbgcolor: "bluegreen-1",
          iconname: "lightning",
          description:
            "Store product attributes and sustainability data on blockchain, without writing code",
        },
        {
          title: "QR code",
          colbgcolor: "sky-1",
          iconname: "moneycheck",
          description:
            "Download QR codes that link to digital product passport information",
        },
        {
          title: "Sustainability viewer",
          colbgcolor: "sky-1",
          iconname: "moneycheck",
          description:
            "Link customers to an online viewer that displays your sustainability information - no downloads required",
        },
      ],
    },
  ],
};

const About = (props) => {
  // const Posts =
  //   props && props.data && props.data.blogs && props.data.blogs.edges
  //     ? props.data.blogs.edges
  //         .filter(
  //           (edge) =>
  //             edge.node.frontmatter.date && edge.node.frontmatter.publish === 1
  //         ) // You can filter your posts based on some criteria
  //         .map((edge) => (
  //           <PostLink home={true} key={edge.node.id} post={edge.node} />
  //         ))
  //     : null;

  return (
    <Layout>
      <div class="gradient-callout-seatosky py-2 mb-2">
        <div class="container my-2 py-4">
          <div class="row">
            <div class="col-lg-10 offset-lg-1">
              <div class="d-flex flex-column align-items-center justify-content-center">
                <h3 class="fw-light text-center white-1">
                  Deliver transparency into product sustainability and comply
                  with new legislation
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="">
        <div class="container container-page my-0 pt-4 pb-2">
          <div class="row">
            <div class="col-12 d-flex flex-column justify-content-center text-center text-lg-center">
              <h1 class="fw-medium black-1">Digital Product Passport</h1>

              <p class="lead">
                Deliver tangible insight into product sustainability and comply
                with regulatory requirements
              </p>
            </div>
          </div>
        </div>
      </div>
      <AlternatingCallout featureData={useCaseData} />

      <hr size={1} />
      <div class="mt-3 mx-2 mx-lg-5 ">
        <div class="my-2 d-flex flex-column ">
          <div class="gradient-callout-algae rounded-corners p-3 py-5 d-flex flex-column align-items-center justify-content-center">
            <h2 class="fw-bold white-1">How it works</h2>
            <p class="lead white-1 text-center">
              PicoNext can help you prepare to deliver Digital Product Passports
              to your customers
            </p>
            <div class="d-flex flex-row flex-wrap align-items-stretch justify-content-center white-1 mt-3 mb-4">
              {featureData.rowdata[0].rowdata.map((item) => (
                <FeatureLink
                  title={item.title}
                  desc={item.description}
                  image={item.image ? item.image : ""}
                  color="white-1"
                />
              ))}
            </div>
            <div class="d-flex flex-column">
              <Link to="/demo/video/" class="mt-2 btn btn-cta-outline">
                See demo &rarr;
                {/* <FaArrowRight /> */}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <hr size={1} />

      <div class="">
        <div class="container my-2 py-4">
          <div class="row">
            <div class="col-12">
              <h3 class="text-center mb-4 black-4">
                See how to create a Digital Product Passport
              </h3>
              <div class="d-flex flex-row flex-wrap justify-content-center">
                <YoutubeComponent id="ShMhe4EBKsg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default About;

export const Head = ({ data }) => {
  return (
    <Metatags
      title="Digital Product Passport Solution"
      description="Deliver insight into product sustainability and comply with regulatory requirements using Digital Product Passports"
      image={`https://${process.env.HOSTNAME}${data?.metadataImage?.edges?.[0]?.node?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}`}
    />
  );
};

export const pageQuery = graphql`
  query {
    metadataImage: allFile(
      filter: {
        relativePath: {
          eq: "featureperspective/passport-usecases/passport-minting-shoes-R.png"
        }
      }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
