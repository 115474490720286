//React
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

//Gatsby
import { Link } from "gatsby";

//Actions
import {
  getActivationOrder,
  activateAgreement,
  updateAgreementErrors,
} from "../../actions/activateAgreement";

//Packages
import parse from "url-parse";

//Components
import Layout from "../../components/layoutLanding";
import Loading from "../../components/loading";
import PurchaseSuccess from "../../components/purchase/purchaseSuccess";

//Icons

//Redux
const mapStateToProps = ({
  agreementOrderDetails,
  agreementActivationDetails,
  agreementErrorDetails,
  isLoading,
  isLoadingAgreementActivation,
}) => {
  return {
    agreementOrderDetails,
    agreementActivationDetails,
    agreementErrorDetails,
    isLoading,
    isLoadingAgreementActivation,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getActivationOrder: (dc, id) => {
    dispatch(getActivationOrder(dc, id));
  },
  activateAgreement: (agreementData) => {
    dispatch(activateAgreement(agreementData));
  },
  updateAgreementErrors: (agreementErrors) => {
    dispatch(updateAgreementErrors(agreementErrors));
  },
});

const Snippet = (props) => {
  const {
    getActivationOrder,
    activateAgreement,
    updateAgreementErrors,
    agreementOrderDetails,
    agreementActivationDetails,
    agreementErrorDetails,
    isLoading,
    isLoadingAgreementActivation,
  } = props;

  const [nameInput, setNameInput] = useState("");
  const [titleInput, setTitleInput] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);

  useEffect(() => {
    const url = parse(window.location.href, true);

    console.log("activate - url");
    console.log(url);

    if (url.pathname) {
      const split = url.pathname.split("/");
      console.log("activate - split");
      console.log(split);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      getActivationOrder(split[3], split[4]);
    }
  }, []);

  const submitAgreement = () => {
    updateAgreementErrors({});

    if (!agreementOrderDetails.id || !agreementOrderDetails.cid) {
      updateAgreementErrors({
        message:
          "Could not load the agreement data. Please contact your PicoNext representative.",
      });
    } else if (!nameInput) {
      updateAgreementErrors({ message: "Please enter your name", id: "name" });
    } else if (!titleInput) {
      updateAgreementErrors({
        message: "Please enter your title",
        id: "title",
      });
    } else if (!emailInput) {
      updateAgreementErrors({
        message: "Please enter your email",
        id: "email",
      });
    } else {
      activateAgreement({
        orderid: agreementOrderDetails.id,
        cid: agreementOrderDetails.cid,
        approvedby: { name: nameInput, email: emailInput, title: titleInput },
        dc: agreementOrderDetails.dc,
      });
    }
  };
  const formatAgreement = (agreementData) => {
    return agreementData.map((item, i) => {
      if (item.type === "pageheader") {
        return <h5>{item.content}</h5>;
      } else if (item.type === "section") {
        return <h6 class="text-uppercase">{item.content}</h6>;
      } else if (item.type === "term") {
        return <p class="term">{item.content.content}</p>;
      } else if (item.type === "term-ul") {
        return (
          <p class="term-ul">
            <div class="bullet">&#8729;</div> {item.content.content}
          </p>
        );
      } else if (item.type === "term-ul-sub") {
        return (
          <p class="term-ul-sub">
            <div class="bullet">&#9702;</div> {item.content.content}
          </p>
        );
      } else if (item.type === "term-ol") {
        return (
          <p class="term-ol">
            {item.content.header}. {item.content.content}
          </p>
        );
      } else {
        return <></>;
      }
    });
  };
  // let itemId = 0;
  return (
    <Layout>
      <div class="container container-purchase">
        <div class="row">
          <div class="col-md-12">
            {agreementErrorDetails &&
            agreementErrorDetails.data &&
            agreementErrorDetails.data.message ? (
              <div class="mt-3 border-1-red-1 red-1 mb-2 p-2 rounded-corners">
                {agreementErrorDetails.data.message}
              </div>
            ) : null}
            {isLoading ? (
              <div class="w-100 p-4">
                <Loading class="small d-flex align-self-center" />
              </div>
            ) : null}
            <h1 class="mt-4 h2">{agreementOrderDetails.projectname}</h1>

            {agreementActivationDetails &&
            agreementOrderDetails.ordertype &&
            agreementActivationDetails.success ? (
              <>
                <hr size={1} />
                <div class="mt-4">
                  <h3>Success</h3>
                  <p className="lead">
                    Thanks for completing your agreement. We've sent a copy to
                    the email address you provided.
                  </p>
                </div>
              </>
            ) : agreementActivationDetails &&
              agreementActivationDetails.success ? (
              // <div class="border-1-green-1 green-1 mb-2 p-2 rounded-corners">
              //   Success! You've activated your PicoNext account. We've sent a
              //   copy of the agreement to your email.
              // </div>
              <PurchaseSuccess
                emailInput={emailInput}
                provisionedUsersNumber={1}
                outboundReferralCode={""}
                copiedToClipboard={copiedToClipboard}
                setCopiedToClipboard={setCopiedToClipboard}
              />
            ) : (
              <>
                {agreementOrderDetails.proposalAgreementData &&
                agreementOrderDetails.proposalAgreementData.length > 0 &&
                agreementOrderDetails.serviceAgreementData &&
                agreementOrderDetails.serviceAgreementData.length > 0 ? (
                  <>
                    <p>
                      To activate your account, please review and accept the
                      agreements below.
                    </p>
                    <hr size={1} />
                  </>
                ) : agreementOrderDetails.serviceAgreementData &&
                  agreementOrderDetails.serviceAgreementData.length > 0 ? (
                  <>
                    <p>Please review and accept the agreement below.</p>
                    <hr size={1} />
                  </>
                ) : null}

                {agreementOrderDetails.proposalAgreementData &&
                agreementOrderDetails.proposalAgreementData.length > 0 ? (
                  <>
                    <h3 class="mt-3 py-2">Proposal Agreement</h3>
                    <div class="order-details order-details-agreement">
                      {formatAgreement(
                        agreementOrderDetails.proposalAgreementData
                      )}
                    </div>
                  </>
                ) : null}
                {agreementOrderDetails.serviceAgreementData &&
                agreementOrderDetails.serviceAgreementData.length > 0 ? (
                  <>
                    <h3 class="mt-3 py-2">
                      {agreementOrderDetails.proposalAgreementData &&
                      agreementOrderDetails.proposalAgreementData.length > 0
                        ? "Service"
                        : ""}{" "}
                      Agreement
                    </h3>
                    <div class="order-details order-details-service">
                      {formatAgreement(
                        agreementOrderDetails.serviceAgreementData
                      )}
                    </div>
                  </>
                ) : null}
                {agreementOrderDetails.proposalAgreementData &&
                agreementOrderDetails.serviceAgreementData ? (
                  <>
                    <hr size={1} />
                    <div class="h6">
                      For{" "}
                      {agreementOrderDetails.companylegalname
                        ? agreementOrderDetails.companylegalname
                        : agreementOrderDetails.companyname}{" "}
                    </div>
                    <div class="form-group">
                      <label for="userName">Your name</label>
                      <input
                        type="text"
                        class={`form-control form-control-sm ${
                          agreementErrorDetails &&
                          agreementErrorDetails.id === "name"
                            ? "is-invalid"
                            : ""
                        }`}
                        id="userName"
                        placeholder="Your name"
                        value={nameInput}
                        onChange={(e) => setNameInput(e.currentTarget.value)}
                        tabIndex="3"
                      />
                    </div>
                    <div class="form-group">
                      <label for="userTitle">Your title</label>
                      <input
                        type="text"
                        class={`form-control form-control-sm ${
                          agreementErrorDetails &&
                          agreementErrorDetails.id === "title"
                            ? "is-invalid"
                            : ""
                        }`}
                        id="userTitle"
                        placeholder="Your title"
                        value={titleInput}
                        onChange={(e) => setTitleInput(e.currentTarget.value)}
                        tabIndex="4"
                      />
                    </div>
                    <div class="form-group">
                      <label for="userEmail">Your email</label>
                      <input
                        type="text"
                        class={`form-control form-control-sm ${
                          agreementErrorDetails &&
                          agreementErrorDetails.id === "email"
                            ? "is-invalid"
                            : ""
                        }`}
                        id="userEmail"
                        placeholder="Your email"
                        value={emailInput}
                        onChange={(e) => setEmailInput(e.currentTarget.value)}
                        tabIndex="5"
                      />
                    </div>
                    <div class="d-flex flex-row align-items-center justify-content-center">
                      <Link
                        class="btn btn-link gray-3 mt-2"
                        to="/"
                        onClick={() => {
                          // updatePurchasePositionNumber(1)
                          // updatePurchaseErrors({})
                        }}
                      >
                        Cancel
                      </Link>
                      <button
                        class="btn btn-primary mt-2"
                        onClick={() => {
                          submitAgreement();
                        }}
                      >
                        I agree
                        {isLoadingAgreementActivation ? (
                          <Loading class="small" />
                        ) : null}
                      </button>
                    </div>
                    <hr size={1} />
                  </>
                ) : null}
              </>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Snippet);
