//React
import React from "react";

//Gatsby
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

//Packages

//Components
import Layout from "../../components/layout";
import { Metatags } from "../../components/marketing/metatags";

import AlternatingCallout from "../../components/marketing/alternatingCallout";
import CalloutSupportSection from "../../components/marketing/calloutSupportSection";
import FeatureQuad from "../../components/marketing/featureQuad";

//Icons

const featureOverviewData = [
  // {
  //   title: 'Design',
  //   description:
  //     'Scale digital experience token creation with a no-code design tool that lets you craft beautiful utility tokens from templates, including with video',
  //   image: 'nft1LoyaltyB',
  //   iconShow: false,
  //   //iconContext: 'imageedit',
  //   iconColor: 'yellow',
  //   iconcolor: 'white',
  //   iconfill: 'white',
  //   lineClass: 'gray-5',
  //   anchor: 'equip',
  //   buttonText: 'More about designing token experiences',
  //   buttonPath: '/platform/create-web3/',
  //   // bulletclass: 'rounded-corners bg-gray-7',
  //   // bullets: [
  //   //   {
  //   //     title: 'Get started with templates',
  //   //     body: 'Use starter NFT templates targeted to your use case to get going quickly',
  //   //   },
  //   //   {
  //   //     title: 'Customize and go',
  //   //     body: 'Quickly customize NFT template content with your own videos, images, stickers, and text - without having to hire a design team',
  //   //   },
  //   //   {
  //   //     title: 'Easy, no-code NFT creation',
  //   //     body: 'Rapidly create engaging NFTs with a no-code tool, without having to hire specialized NFT developers or experts',
  //   //   },
  //   // ],
  // },

  {
    title: "Digital Product Passport Planner",
    description:
      "Gather sustainability data, manage products, and prepare your Digital Product Passport using a no-charge, template-based tool",
    image: "nft5FeatureOverviewAlt",
    iconShow: false,
    // //iconContext: "coin",
    iconColor: "green",
    iconcolor: "white",
    iconfill: "white",
    lineClass: "gray-5",
    anchor: "share",
    buttonText: "More about DPP Planner",
    buttonPath: "/platform/digital-product-passport-planner/",
    // bulletclass: 'rounded-corners bg-gray-7',
    // bullets: [
    //   {
    //     title: 'Integrated NFT minting',
    //     body: 'Use integrated NFT minting to register your NFT on the blockchain, creating a unique asset for your customers',
    //   },
    //   {
    //     title: 'Batch minting',
    //     new: true,
    //     body: 'Merge metadata and media assets to create hundreds of unique NFTs. Preview NFTs before minting and edit metadata on-the-fly before finalizing.',
    //   },
    //   {
    //     title: 'Multichain minting',
    //     new: true,
    //     body: 'Mint on either the standard Ethereum blockchain or the more eco-friendly Polygon blockchain',
    //   },
    //   {
    //     title: 'Smart contracts',
    //     body: 'Use either ERC-721 or ERC-1155 smart contract types to mint your NFTs on the blockchain',
    //   },

    //   {
    //     title: 'Set NFT metadata',
    //     body: "Enhance your NFT by adding metadata directly within the integrated NFT minting tool, to describe and enhance your NFT's assets",
    //   },
    // ],
  },

  {
    title: "Generative AI",
    description:
      "Use generative AI to summarize complex sustainability data into DPPs",
    image: "nft8FeatureOverviewAlt",
    iconShow: false,
    // //iconContext: "coin",
    iconColor: "green",
    iconcolor: "white",
    iconfill: "white",
    lineClass: "gray-5",
    anchor: "share",
    buttonText: "More about Generative AI for DPPs",
    buttonPath: "/platform/generative-ai/",
    // bulletclass: 'rounded-corners bg-gray-7',
    // bullets: [
    //   {
    //     title: 'Integrated NFT minting',
    //     body: 'Use integrated NFT minting to register your NFT on the blockchain, creating a unique asset for your customers',
    //   },
    //   {
    //     title: 'Batch minting',
    //     new: true,
    //     body: 'Merge metadata and media assets to create hundreds of unique NFTs. Preview NFTs before minting and edit metadata on-the-fly before finalizing.',
    //   },
    //   {
    //     title: 'Multichain minting',
    //     new: true,
    //     body: 'Mint on either the standard Ethereum blockchain or the more eco-friendly Polygon blockchain',
    //   },
    //   {
    //     title: 'Smart contracts',
    //     body: 'Use either ERC-721 or ERC-1155 smart contract types to mint your NFTs on the blockchain',
    //   },

    //   {
    //     title: 'Set NFT metadata',
    //     body: "Enhance your NFT by adding metadata directly within the integrated NFT minting tool, to describe and enhance your NFT's assets",
    //   },
    // ],
  },
  {
    title: "Publish Blockchain & Cloud DPPs",
    description:
      "Publish DPPs on a blockchain for transparency & traceability, or in the cloud for enhanced flexibility",
    image: "nft6FeatureOverview",
    iconShow: false,
    // //iconContext: "coin",
    iconColor: "green",
    iconcolor: "white",
    iconfill: "white",
    lineClass: "gray-5",
    anchor: "share",
    buttonText: "More about blockchain & cloud DPPs",
    buttonPath: "/platform/create-dpp/",
    // bulletclass: 'rounded-corners bg-gray-7',
    // bullets: [
    //   {
    //     title: 'Integrated NFT minting',
    //     body: 'Use integrated NFT minting to register your NFT on the blockchain, creating a unique asset for your customers',
    //   },
    //   {
    //     title: 'Batch minting',
    //     new: true,
    //     body: 'Merge metadata and media assets to create hundreds of unique NFTs. Preview NFTs before minting and edit metadata on-the-fly before finalizing.',
    //   },
    //   {
    //     title: 'Multichain minting',
    //     new: true,
    //     body: 'Mint on either the standard Ethereum blockchain or the more eco-friendly Polygon blockchain',
    //   },
    //   {
    //     title: 'Smart contracts',
    //     body: 'Use either ERC-721 or ERC-1155 smart contract types to mint your NFTs on the blockchain',
    //   },

    //   {
    //     title: 'Set NFT metadata',
    //     body: "Enhance your NFT by adding metadata directly within the integrated NFT minting tool, to describe and enhance your NFT's assets",
    //   },
    // ],
  },
  {
    title: "Digital Product Passport Data Events",
    description:
      "Send sustainability data to a blockchain Digital Product Passport",
    image: "nft7FeatureOverviewAlt",
    iconShow: false,
    // //iconContext: "coin",
    iconColor: "green",
    iconcolor: "white",
    iconfill: "white",
    lineClass: "gray-5",
    anchor: "share",
    buttonText: "More about DPP Data Events",
    buttonPath: "/platform/digital-product-passport-data-events/",
    // bulletclass: 'rounded-corners bg-gray-7',
    // bullets: [
    //   {
    //     title: 'Integrated NFT minting',
    //     body: 'Use integrated NFT minting to register your NFT on the blockchain, creating a unique asset for your customers',
    //   },
    //   {
    //     title: 'Batch minting',
    //     new: true,
    //     body: 'Merge metadata and media assets to create hundreds of unique NFTs. Preview NFTs before minting and edit metadata on-the-fly before finalizing.',
    //   },
    //   {
    //     title: 'Multichain minting',
    //     new: true,
    //     body: 'Mint on either the standard Ethereum blockchain or the more eco-friendly Polygon blockchain',
    //   },
    //   {
    //     title: 'Smart contracts',
    //     body: 'Use either ERC-721 or ERC-1155 smart contract types to mint your NFTs on the blockchain',
    //   },

    //   {
    //     title: 'Set NFT metadata',
    //     body: "Enhance your NFT by adding metadata directly within the integrated NFT minting tool, to describe and enhance your NFT's assets",
    //   },
    // ],
  },

  // {
  //   title: "Communities",
  //   description:
  //     "Enhance your DPP with branded communities that include curated, token-gated content",
  //   image: "nft4FeatureOverview",

  //   iconShow: false,
  //   // //iconContext: "groups",
  //   iconColor: "dkblue",
  //   iconcolor: "dkblue",
  //   iconfill: "white",
  //   lineClass: "gray-5",
  //   anchor: "engage",
  //   buttonText: "More about token-gated communities",
  //   buttonPath: "/platform/communities/",
  //   // bulletclass: 'rounded-corners bg-gray-7',
  //   // bullets: [
  //   //   {
  //   //     title: 'Deliver exclusive content',
  //   //     body: 'Deliver exclusive content to NFT holders via turnkey landing pages that authenticate users via their NFTs',
  //   //   },
  //   //   {
  //   //     title: 'Message your brand community',
  //   //     body: 'Collect mobile numbers and deliver real-time updates to your registered NFT holders via text message to create an engaged community ',
  //   //   },
  //   //   {
  //   //     title: 'Benefit from branded NFT use cases',
  //   //     body: 'Deliver programmable brand value for specific use cases - including loyalty programs, events, social impact and more',
  //   //   },
  //   // ],
  // },
  {
    title: "Loyalty",
    description:
      "Enable your blockchain-based DPP with loyalty rewards that keep customers close to your brand",
    image: "nftLoyaltyRedeem2",
    iconShow: false,
    // //iconContext: "loyalty",
    iconColor: "purple",
    iconcolor: "white",
    iconfill: "white",
    lineClass: "gray-5",
    anchor: "share",
    buttonText: "More about loyalty",
    buttonPath: "/platform/loyalty/",
    // bulletclass: 'rounded-corners bg-gray-7',
    // bullets: [
    //   {
    //     title: 'Integrated NFT minting',
    //     body: 'Use integrated NFT minting to register your NFT on the blockchain, creating a unique asset for your customers',
    //   },
    //   {
    //     title: 'Batch minting',
    //     new: true,
    //     body: 'Merge metadata and media assets to create hundreds of unique NFTs. Preview NFTs before minting and edit metadata on-the-fly before finalizing.',
    //   },
    //   {
    //     title: 'Multichain minting',
    //     new: true,
    //     body: 'Mint on either the standard Ethereum blockchain or the more eco-friendly Polygon blockchain',
    //   },
    //   {
    //     title: 'Smart contracts',
    //     body: 'Use either ERC-721 or ERC-1155 smart contract types to mint your NFTs on the blockchain',
    //   },

    //   {
    //     title: 'Set NFT metadata',
    //     body: "Enhance your NFT by adding metadata directly within the integrated NFT minting tool, to describe and enhance your NFT's assets",
    //   },
    // ],
  },

  // {
  //   title: 'Branded marketplaces',
  //   description:
  //     'Sell utility-enabled, experience tokens with a token marketplace that keeps users on your branded channels',
  //   image: 'nft3FeatureOverview',

  //   iconShow: false,
  //   //iconContext: 'shoppingbag',
  //   iconColor: 'sky',
  //   iconcolor: 'sky',
  //   iconfill: 'white',
  //   // lineClass: 'gray-5',
  //   anchor: 'sell',
  //   buttonText: 'More about branded token marketplaces',
  //   buttonPath: '/platform/branded-web3-marketplaces/',
  //   //   bulletclass: 'rounded-corners bg-gray-7',
  //   //   bullets: [
  //   //     {
  //   //       title: 'Create a branded marketplace',
  //   //       new: true,
  //   //       body: 'Quickly spin up one - or many - marketplace pages, and customize with your brand assets and messaging',
  //   //     },
  //   //     {
  //   //       title: 'Set NFT pricing and quantity',
  //   //       new: true,
  //   //       body: 'Set pricing for NFTs and list for sale in your storefront in the quantities your project requires',
  //   //     },
  //   //     {
  //   //       title: 'Use a marketplace smart contract',
  //   //       new: true,
  //   //       body: 'Use a pre-defined marketplace smart contract to transfer sold NFTs to purchasers and remit crypto funds back to you',
  //   //     },
  //   //   ],
  // },
  // {
  //   title: 'Discover & promote your NFTs',
  //   description:
  //     'Use listing, promotion, and discoverability tools to make customers aware of your NFTs, including on external NFT marketplaces and on your own branded properties',
  //   image: 'featureTemplateTravel',

  //   iconShow: true,
  //   //iconContext: 'iphone',
  //   iconColor: 'dkgreen',
  //   iconcolor: 'white',
  //   iconfill: 'white',
  //   // lineClass: 'dkorange-1',
  //   anchor: 'create',
  // },
];

const extraFeatureData = {
  title: "Other capabilities",
  titlecolor: "black-1",
  mode: "quad",
  bggradient: "gradient-callout-silver-reverse",
  rowdata: [
    {
      // rowbgcolor: "green",
      rowdata: [
        // {
        //   title: 'User Onboarding',
        //   // colbgcolor: 'blue-1',
        //   titlecolor: 'dkblue-1',
        //   colbordercolor: 'dkblue-1',
        //   iconname: 'speedometer',
        //   iconcolor: 'dkblue',
        //   desccolor: 'gray-1',
        //   description:
        //     'Ramp users into your project and remove friction points',
        //   href: '/platform/web3-onboarding/',
        // },
        // {
        //   title: 'Branded marketplaces',
        //   // colbgcolor: 'blue-1',
        //   titlecolor: 'dkblue-1',
        //   colbordercolor: 'dkblue-1',
        //   iconname: 'storefront',
        //   iconcolor: 'dkblue',
        //   desccolor: 'gray-1',
        //   description:
        //     'Sell utility-enabled, experience tokens with a branded marketplace',
        //   href: '/platform/branded-web3-marketplaces/',
        // },
        // {
        //   title: 'DPP Data Events',
        //   // colbgcolor: 'blue-1',
        //   titlecolor: 'dkblue-1',
        //   colbordercolor: 'dkblue-1',
        //   iconname: 'calendar',
        //   iconcolor: 'dkblue',
        //   desccolor: 'gray-1',
        //   description:
        //     'Send sustainability data to your Digital Product Passport',
        //   href: '/platform/digital-product-passport-data-events/',
        // },
        {
          title: "Token Explorer",
          // colbgcolor: 'blue-1',
          titlecolor: "dkblue-1",
          colbordercolor: "dkblue-1",
          iconname: "world",
          iconcolor: "dkblue",
          desccolor: "gray-1",
          description: "Explore DPP data & loyalty rewards",
          href: "/platform/token-explorer/",
        },
        {
          title: "Token Collections",
          // colbgcolor: 'blue-1',
          titlecolor: "dkblue-1",
          colbordercolor: "dkblue-1",
          iconname: "tiles",
          iconcolor: "dkblue",
          desccolor: "gray-1",
          description: "Curate, group, and organize DPPs",
          href: "/platform/token-collections/",
        },
        {
          title: "APIs & Data Management",
          // colbgcolor: 'blue-1',
          titlecolor: "dkblue-1",
          colbordercolor: "dkblue-1",
          iconname: "users",
          iconcolor: "dkblue",
          desccolor: "gray-1",
          description: "Create custom-branded DPPs & manage data workflows",
          href: "/platform/permissions-data-management/",
        },
        // {
        //   title: 'At-a-glance',
        //   // colbgcolor: 'blue-1',
        //   titlecolor: 'dkblue-1',
        //   colbordercolor: 'dkblue-1',
        //   iconname: 'magnify',
        //   iconcolor: 'dkblue',
        //   desccolor: 'gray-1',
        //   description:
        //     'See how the PicoNext platform capabilities work together',
        //   href: '/platform/at-a-glance/',
        // },
      ],
    },
  ],
};

const About = (props) => (
  <Layout>
    <div class="callout-home d-flex align-items-center justify-content-center">
      <div class="container">
        <div class="row">
          <div class="col-md-8 offset-md-2">
            <h1 class="text-center fw-normal">
              Engage customers with <br class="d-none d-lg-block" />
              Digital Product Passports
            </h1>
          </div>
        </div>
      </div>
      <div class="overlay"></div>
      <StaticImage
        src="../../images/stock/unsplash/clark-street-mercantile-P3pI6xzovu0-unsplash.jpg"
        // src="../../images/stock/unsplash/arthur-poulin-NhU0nUR7920-unsplash.jpg"
        alt="PicoNext"
        loading="lazy"
        placeholder="blurred"
        layout="fullWidth"
        // width={800}
        // height={300}
        // style={{ position: `absolute` }}
        style={{
          position: `absolute`,
          // top: 0,
          // left: 0,
          // right: 0,
          // bottom: 0,
          inset: "auto",
          width: "100%",
          height: 400,
          zIndex: 0,
        }}
      />
    </div>
    <div class="">
      <div class="container container-page my-0 pt-5 pb-2">
        <div class="row">
          <div class="col-12 d-flex flex-column justify-content-center text-center text-lg-center">
            {/* <h2 class="fw-light black-1 display-2">
              PicoNext Capabilities
            </h2> */}
            <p class="lead display-4">
              PicoNext helps you deliver customer trust &amp; transparency using
              Digital Product Passports through an end-to-end, no-code platform
            </p>
          </div>
        </div>
      </div>
    </div>
    {/* <FeaturePreviewSectionPlatform /> */}
    {/* <div class="d-flex align-items-center justify-content-center mb-3">
      <Link
        to="/about/features"
        class="btn btn-primary btn-cta mb-1 "
        activeClassName="btn btn-primary btn-cta"
      >
        See more PicoNext features <FaArrowRight class="btn-arrow" />
      </Link>
    </div> */}
    <hr size={1} />
    <h2 class="text-center mt-3 mb-4">Digital Product Passport Platform</h2>
    <AlternatingCallout featureData={featureOverviewData} />
    <FeatureQuad featureData={extraFeatureData} />
    <CalloutSupportSection
      title="launching Digital Product Passports"
      section="all"
      color="bluegreen"
    />
  </Layout>
);

export default About;

export const Head = ({ data }) => {
  return (
    <Metatags
      title="Digital Product Passport Platform"
      description="Deliver customer trust & transparency using Digital Product Passports through an end-to-end, no-code platform - on a blockchain or in the cloud"
      image={`https://${process.env.HOSTNAME}${data?.metadataImage?.edges?.[0]?.node?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}`}
    />
  );
};

export const pageQuery = graphql`
  query {
    metadataImage: allFile(
      filter: {
        relativePath: {
          eq: "stock/unsplash/omid-armin-8xt9BeYQf5U-unsplash.jpg"
        }
      }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
